import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Axios from 'axios';
import useAuth from '../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../setup/util';

type ReviewProps = {
  course: any;
};

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const ReviewForm = ({ course }: ReviewProps) => {
  const { state } = useAuth();
  const { userInfo } = state;
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState<number | null>(null);
  const [review, setReview] = useState<any>();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post(
        `/api/review/`,
        {
          courseid: course._id,
          comment: comment,
          rating: rating,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      setReview(data.data);
      //notify instructor
      const res = await Axios.post(
        '/api/notify/notifyInstructor',
        {
          courseid: course._id,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success(
        'Review Finished and notified the instructor about the review'
      );
    } catch (err) {
      toast.error(getError(err));
    }
    // console.log('Form Submitted', comment, rating);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(
          `/api/review/getByCourse/${course._id}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        setReview(data.data);
      } catch (err: any) {
        if (err.status === 404) {
        }
      }
    };
    fetchData();
  });

  return (
    <div className="flex p-5 ">
      {review ? (
        <div className="flex flex-col justify-start items-center space-y-5 m-auto">
          <StyledRating
            name="highlight-selected-only"
            className=""
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons[value].label}
            highlightSelectedOnly
            value={review.rating}
            onChange={(e, value) => setRating(value)}
          />
          <label>comment</label>
          <span>{review.comment}</span>
        </div>
      ) : (
        <form onSubmit={(e) => onSubmit(e)} className="flex-auto">
          <div className="flex justify-between items-center mx-3">
            <label className="text-sm font-light tracking-wide">
              Tell us your feeling{' '}
            </label>
            <StyledRating
              name="highlight-selected-only"
              className="mr-32"
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              highlightSelectedOnly
              value={rating}
              onChange={(e, value) => setRating(value)}
            />
          </div>
          <div className="mt-2 flex flex-col justify-start">
            <label className="w-full ml-3 text-sm font-light tracking-wide text-left">
              Write your comment here
            </label>
            <textarea
              className="flex-auto my-2 rounded-2xl w-full"
              // type="text"
              placeholder="enter you comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="inline-flex justify-self-end rounded-2xl border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default ReviewForm;
