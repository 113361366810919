import React, { SyntheticEvent, useEffect, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import Axois from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../setup/util';

const SignInScreen = () => {
  const { state, dispatch } = useAuth();
  const { userInfo } = state;
  const navigate = useNavigate();
  const redirect =
    userInfo.role === 'Student'
      ? '/Student'
      : userInfo.role === 'Instructor'
      ? '/Instructor'
      : userInfo.role === 'SuperAdmin'
      ? '/SuperAdmin'
      : '/signin';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRememberMe] = useState('');

  
  const SubmitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      const { data } = await Axois.post('/api/auth/login', {
        email: email,
        password: password,
      });
      const { token, user } = data;
      dispatch({
        type: 'USER_SIGNIN',
        payload: { token, role: user.role, id: user._id },
      });
      localStorage.setItem('id', JSON.stringify(user._id));
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('role', JSON.stringify(user.role));
      navigate(redirect);
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (userInfo.token) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <>
      <div className="bg-[#e9ecef] flex min-h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto">
          <div className="bg-white drop-shadow-xl border border-gray-200 rounded-lg max-w-sm p-4 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
            <form onSubmit={SubmitHandler} className="space-y-6" method="POST">
              <div>
                <img
                  className="mx-auto h-12 w-auto"
                  src="/samesoft.png"
                  alt="Same Soft"
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                  Sign in to your account
                </h2>
              </div>
              {/* <input type="hidden" name="remember" defaultValue="true" /> */}
              <div>
                <label
                  htmlFor="email-address"
                  className="text-left text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  autoComplete="email"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="email address"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="text-left text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  autoComplete="current-password"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Password"
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  />
                  <label
                    htmlFor="remember-me"
                    className="font-medium ml-2 block text-sm text-gray-900 dark:text-gray-300"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="/"
                    className="text-blue-700 ml-auto dark:text-blue-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative flex justify-center w-full text-white bg-[#063A6C] hover:bg-[#063A6C] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockIcon fontSize="small" />
                    {/* <LockClosedIcon
                      className="h-5 w-5 text-white group-hover:text-white"
                      aria-hidden="true"
                    /> */}
                  </span>
                  Sign in
                </button>
              </div>
              <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                Not registered?{' '}
                <a
                  href="/signup"
                  className="text-blue-700 hover:underline dark:text-blue-500"
                >
                  Create account
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInScreen;
