import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import AssignUser from '../AssignUser/index';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from '../../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';
import Search from './Search';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface Data {
  _id: string;
  title: string | number;
  description: string | number;
  SkillToGain: string | number;
  WhatToLearn: string | number;
  Instructor: any;
  Lessons: any;
  active: boolean;
  Action: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Course Title',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Course Description',
  },
  {
    id: 'Lessons',
    numeric: false,
    disablePadding: true,
    label: 'No. Of Lessons',
  },
  { id: 'Action', numeric: false, disablePadding: true, label: 'Action' },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: '20%', p: 2 }}
            // className=""
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              // className="flex justify-center"
            >
              <span className=" text-[12px] font-[600]">{headCell.label}</span>
              {/* {headCell.label} */}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const InstructorCourses = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('title');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [coursedata, setcoursedata] = useState<any>();
  const navigate = useNavigate();
  const [selectedCourse, setselectedCourse] = useState({});
  // const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [instructor, setInstructor] = useState<any>(null);
  const [active, setactive] = React.useState();
  const { state } = useAuth();
  const { userInfo } = state;

  const courseCallback = ({ selected }: { selected: any }) => {
    setInstructor(selected);
  };

  const deActivateHandler = async (
    event: React.MouseEvent<unknown>,
    course: any
  ) => {
    try {
      const { data } = await Axios.post(
        `/api/course/deactivate/${course._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success(data.data, {
        autoClose: 500,
        hideProgressBar: false,
        draggable: true,
        pauseOnHover: false,
      });
      setactive(course);
    } catch (err) {
      // console.log(err);
      toast.error(getError(err));
    }
  };

  const activateHandler = async (
    event: React.MouseEvent<unknown>,
    course: any
  ) => {
    try {
      const { data } = await Axios.post(
        `/api/course/activate/${course._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success(data.data, {
        autoClose: 500,
        hideProgressBar: false,
        draggable: true,
        pauseOnHover: false,
      });
      setactive(course);
    } catch (err) {
      // console.log(err);

      toast.error(getError(err));
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const assignhandler = (event: React.MouseEvent<unknown>, course: any) => {
    setOpen(true);
    setselectedCourse(course);
  };

  const DeleteCoursehandler = async (
    event: React.MouseEvent<unknown>,
    course: any
  ) => {
    await Axios.delete(`/api/course/${course._id}`, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
  };

  const handleClick = (event: React.MouseEvent<unknown>, course: any) => {
    navigate(`../CourseDetail/${course._id}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        if (instructor) {
          const { data } = await Axios.get(
            `/api/course/?Instructor=${instructor.value}&page=${page}&limit=${rowsPerPage}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.token}`,
              },
            }
          );
          setcoursedata(data.data);
        }
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchdata();
  }, [active, instructor, page, rowsPerPage, userInfo]);

  const assignCallback = (payload: any) => {
    setOpen(payload);
  };

  return (
    <>
      {open && (
        <AssignUser
          course={selectedCourse}
          opens={open}
          callback={assignCallback}
        />
      )}
      <div className="mt-5 grid grid-cols-3 gap-6">
        <div className="mt-5 col-span-3">
          <div className="relative flex justify-between gap-5 z-50">
            <div className="px-4 py-3 w-3/5 drop-shadow-xl">
              <div className=" flex flex-row items-center">
                <Search callback={courseCallback} />
              </div>
            </div>
          </div>
          <Box className=" px-4 py-3 w-full h-5/6 ">
            <Paper
              elevation={0}
              variant="elevation"
              sx={{ width: '100%', mb: 2 }}
              className=" drop-shadow-2xl bg-slate-50"
            >
              <TableContainer className="">
                <Table
                  className=" table-auto"
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  {/* selected.length */}
                  <EnhancedTableHead
                    numSelected={0}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={coursedata?.count}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {coursedata &&
                      stableSort(coursedata.data, getComparator(order, orderBy))
                        .slice()
                        // .filter((item) => {
                        //   return search.toLowerCase() === ''
                        //     ? item
                        //     : item.title.toLowerCase().includes(search);
                        // })
                        .map((row, index) => {
                          // const isItemSelected = isSelected(row.title as string);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              sx={{
                                height: 40,
                              }}
                              key={index}
                            >
                              <StyledTableCell
                                component="th"
                                id={labelId}
                                onClick={(event) => handleClick(event, row)}
                                scope="row"
                              >
                                <p className="text-[12px]  h-10 line-clamp-2">
                                  {row.title}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className="text-[12px]  h-10 line-clamp-2">
                                  {row.description}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className="text-[12px]  h-10  line-clamp-2">
                                  {row.Lessons.length}
                                </p>
                              </StyledTableCell>
                              {/* <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className="text-[12px] h-10  line-clamp-2">
                                  {row.WhatToLearn}
                                </p>
                              </StyledTableCell> */}
                              <StyledTableCell align="left">
                                <div className="flex justify-start space-x-1">
                                  <Tooltip
                                    title={
                                      row.active ? 'Deactivate' : 'Activate'
                                    }
                                  >
                                    {row.active ? (
                                      <IconButton
                                        onClick={(event) =>
                                          deActivateHandler(event, row)
                                        }
                                      >
                                        <ToggleOnIcon
                                          fontSize="medium"
                                          className="text-indigo-500"
                                        />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={(event) =>
                                          activateHandler(event, row)
                                        }
                                      >
                                        <ToggleOffIcon
                                          fontSize="medium"
                                          className="text-red-500"
                                        />
                                      </IconButton>
                                    )}
                                  </Tooltip>
                                  <Tooltip title="Assign User">
                                    <IconButton
                                      onClick={(event) =>
                                        assignhandler(event, row)
                                      }
                                    >
                                      <PersonAddIcon
                                        fontSize="small"
                                        className="text-indigo-500"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      onClick={(event) =>
                                        DeleteCoursehandler(event, row)
                                      }
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                        className="text-red-500"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                    {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </div>
    </>
  );
};

export default InstructorCourses;
