import React, { useEffect } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { useNavigate, Link } from 'react-router-dom';
import Axios from 'axios';
import AssignUser from '../AssignUser/index';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from '../../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface Data {
  _id: string;
  title: string | number;
  description: string | number;
  SkillToGain: string | number;
  WhatToLearn: string | number;
  Action: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Course Title',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Course Description',
  },
  {
    id: 'SkillToGain',
    numeric: false,
    disablePadding: true,
    label: 'Skill To Gain',
  },
  {
    id: 'WhatToLearn',
    numeric: false,
    disablePadding: true,
    label: 'What To Learn',
  },
  { id: 'Action', numeric: false, disablePadding: true, label: 'Action' },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: '20%', p: 2 }}
            // className="bg-indigo-500 rounded-t"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              // className="decoration-4"
            >
              <span className="text-[12px] font-[600]">{headCell.label}</span>
              {/* {headCell.label} */}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CourseList = () => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('title');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [coursedata, setcoursedata] = React.useState<any>();
  const navigate = useNavigate();
  const [selectedCourse, setselectedCourse] = React.useState({});
  const [search, setSearch] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openDeleteConfirmation, setQpenDeleteConfirmation] =
    React.useState(false);
  const { state } = useAuth();
  const { userInfo } = state;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const assignhandler = (event: React.MouseEvent<unknown>, course: any) => {
    setOpen(true);
    setselectedCourse(course);
  };

  const DeleteCoursehandler = async (
    event: React.MouseEvent<unknown>,
    course: any
  ) => {
    setQpenDeleteConfirmation(true);
  };

  const handleClick = (event: React.MouseEvent<unknown>, course: any) => {
    navigate(`../CourseDetail/${course._id}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const { data } = await Axios.get(
          `/api/course/?Instructor=${userInfo.id}&page=${page}&limit=${rowsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        setcoursedata(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchdata();
  }, [page, rowsPerPage, userInfo]);

  const assignCallback = (payload: any) => {
    setOpen(payload);
  };

  return (
    <>
      {openDeleteConfirmation && (
        <DeleteConfirmationDialog
          openDialog={openDeleteConfirmation}
          course={selectedCourse}
        />
      )}
      {open && (
        <AssignUser
          course={selectedCourse}
          opens={open}
          callback={assignCallback}
        />
      )}
      <div className="mt-5 grid grid-cols-3 gap-6">
        <div className="mt-5 col-span-3">
          <div className="flex justify-between gap-5">
            <div className="px-4 py-3 drop-shadow-xl">
              <Link
                to={'/Instructor/addCourse'}
                className="drop-shadow-xl inline-flex justify-center rounded-md border border-transparent bg-[#063A6C] py-2 px-4 text-sm font-medium uppercase text-white hover:font-semibold hover:outline hover:bg-white hover:text-[#063A6C] hover:outline-2 hover:outline-[#063A6C] "
                // onClick={addCourseHandler}
              >
                Add Course
              </Link>
            </div>
            <div className="px-4 py-3 w-96 drop-shadow-xl">
              <form className="">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <SearchIcon fontSize="small" />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-[#063A6C] focus:border-[#063A6C] "
                    placeholder="Search courses by title"
                    onChange={(e) => setSearch(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    className="text-white absolute inset-y-0 right-0 bg-[#063A6C] hover:bg-[#063A6C] focus:ring-2 focus:outline-none focus:ring-[#063A6C] font-medium rounded-lg text-sm px-4 py-2 "
                    disabled
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Box className="px-4 py-3 w-full h-5/6 ">
            <Paper
              elevation={0}
              sx={{ width: '100%', mb: 2 }}
              className="drop-shadow-2xl bg-slate-50"
            >
              <TableContainer>
                <Table
                  className="table-auto"
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  {/* selected.length */}
                  <EnhancedTableHead
                    numSelected={0}
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={coursedata?.count}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {coursedata &&
                      stableSort(coursedata.data, getComparator(order, orderBy))
                        .slice()
                        .filter((item) => {
                          return search.toLowerCase() === ''
                            ? item
                            : item.title.toLowerCase().includes(search);
                        })
                        .map((row, index) => {
                          // const isItemSelected = isSelected(row.title as string);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              sx={{
                                height: 40,
                              }}
                              key={index}
                            >
                              <StyledTableCell
                                component="th"
                                id={labelId}
                                onClick={(event) => handleClick(event, row)}
                                scope="row"
                              >
                                <p className="text-[12px] h-10 line-clamp-2">
                                  {row.title}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className="text-[12px] h-10 line-clamp-2">
                                  {row.description}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className="text-[12px] h-10  line-clamp-2">
                                  {row.SkillToGain}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className="text-[12px] h-10  line-clamp-2">
                                  {row.WhatToLearn}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Tooltip title="Assign User">
                                  <IconButton
                                    onClick={(event) =>
                                      assignhandler(event, row)
                                    }
                                  >
                                    <PersonAddIcon
                                      fontSize="small"
                                      className="text-[#063A6C]"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={(event) =>
                                      DeleteCoursehandler(event, row)
                                    }
                                  >
                                    <DeleteIcon
                                      fontSize="small"
                                      className="text-red-500"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                    {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </div>
    </>
  );
};

export default CourseList;
