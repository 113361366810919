import React, { Fragment, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import SchoolIcon from '@mui/icons-material/School';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Menu, Transition } from '@headlessui/react';
import ReactTimeAgo from 'react-time-ago';
import { toast } from 'react-toastify';
import useAuth from '../../../../setup/route-manager/useAuth';
import { getError } from '../../../../setup/util';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const InstructorLayout = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const [getUser, setGetUser] = useState<any>();
  const { userInfo } = state;
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(open ? false : true);
  const [notifications, setNotifications] = useState([]);
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/signin';

  const signoutHandler = async () => {
    const { data } = await Axios.get('/api/auth/logout');
    dispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('id');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get('/api/user/currentUser', {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setGetUser(data.data);
        try {
          const { data } = await Axios.get('/api/notify/', {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          });
          // console.log(data.data);
          setNotifications(data.data);
        } catch (err: any) {
          if (
            err.response.status === 404 &&
            err.response.data.error === 'User is not found'
          ) {
            toast.error(getError(err));
          } else if (
            err.response.status === 404 &&
            err.response.data.error === 'Notification for this user not Found'
          ) {
            toast.error(getError(err));
          } else toast.error(getError(err));
        }
      } catch (err: any) {
        console.log(err);
        if (err.response.status === 404) {
          toast.error(getError(err));
          dispatch({ type: 'USER_SIGNOUT' });
          localStorage.removeItem('token');
          localStorage.removeItem('role');
          navigate(redirect);
        } else {
          toast.error(getError(err));
        }
      }
    };
    fetchData();
  }, [dispatch, navigate, redirect, userInfo.token]);

  return (
    <>
      <main className="bg-white h-screen overflow-hidden relative">
        <div className="h-screen flex items-start justify-between">
          <div
            className={`h-full hidden lg:block relative ${
              open ? 'w-80' : 'w-0'
            }`}
          >
            <div className={`${open ? '' : 'hidden'} bg-[#e9ecef] h-full `}>
              <div
                className={`${open ? '' : 'hidden'}
              flex items-center justify-evenly pt-6 mx-2 h-fit`}
              >
                <img
                  className="block h-12 w-auto lg:hidden mix-blend-multiply"
                  src="/samesoft.png"
                  alt="Same Soft Online Learning"
                />
                <img
                  className="hidden h-12 w-auto lg:block mix-blend-multiply"
                  src="/samesoft.png"
                  alt="Same Soft Online Learning"
                />
                <p className="font-black tracking-wide text-sky-400 text-2xl font-mono">
                  Elearning
                </p>
              </div>
              <nav className="mt-10 h-[494px] ">
                <div className="bg-[#e9ecef] min-h-full h-full flex flex-col overflow-y-scroll overscroll-y-none scrollbar-thin scrollbar-thumb-indigo-100 scrollbar-track-gray-100">
                  <Link
                    className="border-2 rounded-lg mx-4 drop-shadow-xl  text-blue-900 flex items-center p-[7px] pr-2.5 transition-colors duration-200 justify-start hover:bg-[#063A6C] hover:text-white "
                    to="/Instructor"
                  >
                    <span className="flex mr-4 justify-center items-center h-9 w-9 rounded-lg shadow-xl bg-white text-[#0CC2F1]">
                      <HomeIcon fontSize="small" />
                      {/* <HomeIcon className="w-5 h-5" /> */}
                    </span>
                    <span className="text-left text-base font-semibold">
                      Dashboard
                    </span>
                  </Link>
                  <Link
                    className="border-2 rounded-lg mx-4 drop-shadow-xl  text-blue-900 flex items-center p-[7px] pr-2.5 transition-colors duration-200 justify-start hover:bg-[#063A6C] hover:text-white "
                    to="/Instructor/Course"
                  >
                    <span className="flex mr-4 justify-center items-center h-9 w-9 rounded-lg shadow-xl bg-white text-[#0CC2F1]">
                      <SchoolIcon fontSize="small" />
                      {/* <AcademicCapIcon className="w-5 h-5" /> */}
                    </span>
                    <span className="text-left text-base font-semibold">
                      Courses
                    </span>
                  </Link>
                  <Link
                    className="border-2 rounded-lg mx-4 drop-shadow-xl  text-blue-900 flex items-center p-[7px] pr-2.5 transition-colors duration-200 justify-start hover:bg-[#063A6C] hover:text-white "
                    to="/Instructor/User"
                  >
                    <span className="flex mr-4 justify-center items-center h-9 w-9 rounded-lg shadow-xl bg-white text-[#0CC2F1]">
                      <PersonIcon fontSize="small" />
                      {/* <UserIcon className="w-5 h-5" /> */}
                    </span>
                    <span className="text-left text-base font-semibold">
                      Users
                    </span>
                  </Link>
                  <Link
                    className="border-2 rounded-lg mx-4 drop-shadow-xl  text-blue-900 flex items-center p-[7px] pr-2.5 transition-colors duration-200 justify-start hover:bg-[#063A6C] hover:text-white "
                    to="/Instructor/Reviews"
                  >
                    <span className="flex mr-4 justify-center items-center h-9 w-9 rounded-lg shadow-xl bg-white text-[#0CC2F1]">
                      <RateReviewIcon fontSize="small" />
                      {/* <UserIcon className="w-5 h-5" /> */}
                    </span>
                    <span className="text-left text-base font-semibold">
                      Reviews
                    </span>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
          <div className="flex flex-col w-full md:space-y-4">
            <header className="w-full h-16 z-40 flex items-center justify-between">
              <div className="flex flex-col h-full justify-end mx-3">
                <div className="relative p-1 flex items-center w-full space-x-4 justify-end">
                  <button
                    onClick={handleOpen}
                    className="flex p-2 items-center border border-slate-200 rounded-full bg-white shadow-xl text-[#063A6C] text-md hover:bg-[#063A6C] hover:text-white hover:shadow-xl"
                  >
                    <MenuIcon fontSize="small" />
                    {/* <Bars3Icon className="w-5 h-5" /> */}
                  </button>
                </div>
              </div>
              <div className="relative z-20 flex flex-col justify-end h-full px-3 md:w-full">
                <div className="relative p-1 flex items-center w-full space-x-4 justify-end">
                  <Menu as="div" className="relative ml-3">
                    <div className="flex items-center relative text-gray-500 dark:text-white text-md">
                      <Menu.Button className="inline-flex relative p-2 items-center border border-slate-200 rounded-full bg-white shadow-xl text-[#063A6C] text-md hover:bg-[#063A6C] hover:text-white hover:shadow-xl">
                        <span className="sr-only">Open Notification</span>
                        <div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white ">
                          {
                            notifications.filter(
                              (not: any) => not.status === 'unread'
                            ).length
                          }
                        </div>
                        <NotificationsIcon fontSize="small" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="w-80 absolute right-0 mt-2 bg-white rounded-md shadow-lg overflow-hidden z-20 origin-top-right ring-1 ring-black ring-opacity-5 transform opacity-100 focus:outline-none">
                        <div className=" w-full max-h-80 overflow-x-hidden overflow-y-scroll scrollbar-thin scrollbar-thumb-indigo-100 scrollbar-track-gray-100">
                          {notifications.map((notification: any, index) => {
                            return (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <Link
                                    to={`#`}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-[#063A6C]'
                                        : '',
                                      ' text-sm text-gray-700 flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2'
                                    )}
                                  >
                                    <img
                                      className="h-8 w-8 rounded-full object-cover mx-1"
                                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                                      alt="avatar"
                                    />
                                    <span className="text-gray-600 text-sm mx-2">
                                      <span className="font-bold">
                                        {notification?.creator?.name}
                                      </span>{' '}
                                      {notification?.title}{' '}
                                      <span className="font-bold text-blue-500">
                                        {notification?.feedbackObj?.title}
                                      </span>{' '}
                                      <ReactTimeAgo
                                        date={new Date(notification?.createdAt)}
                                        locale="en-US"
                                      />
                                    </span>
                                  </Link>
                                )}
                              </Menu.Item>
                            );
                          })}
                        </div>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`#`}
                              className={classNames(
                                active ? 'bg-gray-100 text-[#063A6C]' : '',
                                'block bg-gray-800 text-white text-center font-bold py-2 hover:bg-[#063A6C]'
                              )}
                            >
                              {' '}
                              See all notifications
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <span className="w-1 h-8 rounded-lg bg-[#063A6C]"></span>

                  <Menu as="div" className="relative ml-3">
                    <div className="flex items-center relative text-gray-500 dark:text-white text-md">
                      <span className="text-left text-base pr-2 font-bold capitalize">
                        {getUser?.name}
                      </span>
                      <Menu.Button className="flex rounded-full bg-[#063A6C] text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-9 w-9 rounded-full shadow-xl"
                          src={`/${getUser?.profileImageUrl?.replace(
                            'public',
                            'localFile'
                          )}`}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`./profile`}
                              className={classNames(
                                active ? 'bg-gray-100 text-[#063A6C]' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Profile
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/signout"
                              onClick={signoutHandler}
                              className={classNames(
                                active ? 'bg-gray-100 text-[#063A6C]' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Sign out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </header>
            {/* // overflow-scroll scrollbar-thin scrollbar-thumb-indigo-100 scrollbar-track-gray-100 */}
            <div className="h-screen pb-24 px-4 md:px-6 overflow-scroll scrollbar-thin scrollbar-thumb-indigo-100 scrollbar-track-gray-100">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default InstructorLayout;
