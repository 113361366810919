import React, { useEffect, useState } from 'react';
import CountrySearch from './CountrySearch';
import {
  Box,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ApprovalIcon from '@mui/icons-material/Approval';
import Axios from 'axios';
import useAuth from '../../../../setup/route-manager/useAuth';
import { visuallyHidden } from '@mui/utils';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

interface Data {
  name: string | number;
  Action: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Country Name',
  },
  // {
  //   id: 'description',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Course Description',
  // },
  // {
  //   id: 'SkillToGain',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Skill To Gain',
  // },
  // {
  //   id: 'WhatToLearn',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'What To Learn',
  // },
  { id: 'Action', numeric: false, disablePadding: true, label: 'Action' },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: '20%', p: 2 }}
            // className="bg-indigo-500 rounded-t"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              // className="decoration-4"
            >
              <span className="text-[12px] font-[600]">{headCell.label}</span>
              {/* {headCell.label} */}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type Props = {};

const ApprovedCountry = (props: Props) => {
  const [country, setCountry] = useState<any>(null);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [countryData, setCountryData] = useState<any>();
  const { state } = useAuth();
  const { userInfo } = state;

  const selectedCountryCallback = ({ selected }: { selected: any }) => {
    setCountry(selected);
  };


  const handleApprove = async () => {
    console.log(country?.value)
    console.log(userInfo.token)
    try {
      const { data } = await Axios.post(
        `/api/user/approvedCountry`,
        {
          country: country?.value,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
    } catch (err) {
      toast.error(getError(err));
    }
    setCountry(null);
  };

  const handleDeapprove = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    country: any
  ) => {
    try {
      const { data } = await Axios.delete(
        `/api/user/deapproveCountry/${country._id}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      setCountry(country);
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const { data } = await Axios.get(
          `/api/user/approvedCountry?page=${page}&limit=${rowsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        setCountryData(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchdata();
  }, [country, page, rowsPerPage, userInfo]);

  return (
    <div className="mt-10">
      <div className="relative flex flex-row justify-between items-center z-30">
        <h1 className="text-left pl-4 font-semibold text-lg ">
          Approved Counties
        </h1>
        <div className="basis-2/5 px-4 flex flex-row items-center space-x-2">
          <div className="grow drop-shadow-xl">
            <div className="flex flex-row items-center">
              <CountrySearch
                callback={selectedCountryCallback}
                country={country}
              />
            </div>
          </div>
          <IconButton onClick={handleApprove}>
            <Tooltip title="Approve">
              <ApprovalIcon fontSize="medium" className="text-indigo-500" />
            </Tooltip>
          </IconButton>
        </div>
      </div>

      <div className="mt-5 px-4 h-52 flex flex-row space-x-5">
        <Box className="px-4 py-3 w-full h-5/6 z-0">
          <Paper
            elevation={0}
            sx={{ width: '100%', mb: 2 }}
            className="drop-shadow-2xl bg-slate-50"
          >
            <TableContainer>
              <Table
                className="table-auto"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
              >
                {/* selected.length */}
                <EnhancedTableHead
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={countryData?.count}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                  {countryData &&
                    stableSort(countryData.data, getComparator(order, orderBy))
                      .slice()
                      // .filter((item) => {
                      //   return search.toLowerCase() === ''
                      //     ? item
                      //     : item.title.toLowerCase().includes(search);
                      // })
                      .map((row, index) => {
                        // const isItemSelected = isSelected(row.title as string);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow hover key={index}>
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                            >
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Tooltip title="Remove">
                                <IconButton
                                  onClick={(event) =>
                                    handleDeapprove(event, row)
                                  }
                                >
                                  <RemoveCircleOutlineIcon
                                    fontSize="small"
                                    className="text-red-500"
                                  />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </div>
  );
};

export default ApprovedCountry;
