/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Tooltip } from '@mui/material';
import Lesson from './Lesson';
import { toast } from 'react-toastify';
import useAuth from '../../../../setup/route-manager/useAuth';
import { getError } from '../../../../setup/util';

enum flag {
  delete = 'Delete',
  edit = 'Edit',
}

const DetailCourse = () => {
  const { state } = useAuth();
  const { userInfo } = state;
  const [isOpen, setOpen] = useState(true);
  const [isobjectiveOpen, setobjectiveOpen] = useState(true);
  const [course, setCourse] = useState<any>(null);
  const { id } = useParams();
  const [enableEdit, setEnableEdit] = useState(false);
  const [openEditCourse, setOpenEditCourse] = useState(false);
  const [courseTitle, setCourseTitle] = useState<any>('');
  const [courseDescription, setCourseDescription] = useState<any>('');
  const [courseWhatToLearn, setCourseWhatToLearn] = useState<any>('');
  const [courseSkillToGain, setCourseSkillToGain] = useState<any>('');
  const [lessonsObj, setLessonsObj] = useState<any>([]);

  // console.log(course);

  const LessonCallback = ({
    Lesson,
    flag,
  }: {
    Lesson: { id: string; title: string; description: string; Video: any[] };
    flag: flag;
  }) => {
    if (flag === 'Edit') {
      //Edit video
      let editedLesson = lessonsObj.map((lesson: any) => {
        if (Lesson.id === lesson._id) {
          lesson.title = Lesson.title;
          lesson.description = Lesson.description;
          lesson.Video = Lesson.Video;
        }
        return lesson;
      });
      setLessonsObj(editedLesson);
    }
    if (flag === 'Delete') {
      //Delete Video
      setLessonsObj(
        lessonsObj.filter((lesson: any) => Lesson.id !== lesson._id)
      );
    }
  };
  // console.log(lessonsObj);

  const handleCourseDropDown = () => {
    setOpen(!isOpen);
  };

  const handleObjectiveDropDown = () => {
    setobjectiveOpen(!isobjectiveOpen);
  };

  const handleEditCourse = async () => {
    if (!enableEdit) {
      //start editor
      setobjectiveOpen(true);
      setOpenEditCourse(true);
      setCourseTitle(course?.title);
      setCourseDescription(course?.description);
      setCourseWhatToLearn(course?.WhatToLearn);
      setCourseSkillToGain(course?.SkillToGain);
      setLessonsObj(course?.Lessons);
      setEnableEdit(true);
    } else {
      //save Handler
      setEnableEdit(false);
      setOpenEditCourse(false);
      try {
        const { data } = await Axios.put(
          `/api/course/${course._id}`,
          {
            title: courseTitle,
            description: courseDescription,
            WhatToLearn: courseWhatToLearn,
            SkillToGain: courseSkillToGain,
            Lessons: lessonsObj,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        setCourse(data.data);
        toast.success(data.status);
      } catch (err) {
        toast.error(getError(err));
      }
    }
  };

  // const savesubmitHandler = () => {
  //   setEnableEdit(false);
  //   setOpenEditCourse(false);
  // };

  const checkEnterforwhattolearn = (e: any) => {
    if (e.key === 'Enter') {
      // console.log(String.fromCharCode());
      setCourseWhatToLearn(courseWhatToLearn + '•' + ' ');
    }
  };
  const checkEnterforskilltogain = (e: any) => {
    if (e.key === 'Enter') {
      // console.log(String.fromCharCode());
      setCourseSkillToGain(courseSkillToGain + '•' + ' ');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Axios.get(`/api/course/${id}`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setCourse(data.data);
      // console.log(data.data);
    };
    fetchData();
  }, [id, userInfo.token]);

  return (
    <>
      <div className="mt-5 grid grid-cols-3 gap-6">
        <div className="mt-5 col-span-3">
          <div className="px-4">
            <div className="drop-shadow-2xl sm:overflow-hidden sm:rounded-lg w-full">
              <div className=" bg-slate-100 px-4 py-5 sm:p-6 ">
                <div className="flex justify-between items-center">
                  <button
                    id="coursedetaildropdown"
                    onClick={handleCourseDropDown}
                    className={`${
                      openEditCourse ? 'hidden' : ''
                    } w-full uppercase font-bold inline-flex outline-none justify-between items-center text-lg text-left text-gray-700 focus:outline-none `}
                    type="button"
                  >
                    {courseTitle ? courseTitle : course?.title}
                    {isOpen ? (
                      <KeyboardArrowUpIcon
                        fontSize="medium"
                        className="text-indigo-500"
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        fontSize="medium"
                        className="text-indigo-500"
                      />
                    )}
                  </button>
                  <input
                    type="text"
                    name="title"
                    value={courseTitle}
                    onChange={(e) => setCourseTitle(e.target.value)}
                    placeholder="Edit Course title"
                    className={`${
                      openEditCourse ? '' : 'hidden'
                    } w-2/5 h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                  />

                  <Tooltip title="save">
                    <IconButton
                      onClick={(e) => handleEditCourse()}
                      className="w-9 h-9 ml-5 flex justify-center text-indigo-500  hover:text-white hover:bg-indigo-500 font-small focus:outline-none"
                    >
                      {openEditCourse ? (
                        <SaveIcon fontSize="medium" />
                      ) : (
                        <EditIcon fontSize="medium" />
                      )}
                      {/* <span className="">{openEditTitle ? 'Done' : 'Edit'}</span> */}
                    </IconButton>
                  </Tooltip>
                </div>
                <div
                  className={`pl-5 w-full text-gray-700 text-left  ${
                    isOpen ? '' : 'hidden'
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div
                      className={`${
                        openEditCourse ? 'hidden' : ''
                      } pl-3 text-sm  my-3`}
                    >
                      {courseDescription
                        ? courseDescription
                        : course?.description}
                    </div>
                    <textarea
                      id="about"
                      name="about"
                      rows={3}
                      value={courseDescription}
                      placeholder="Edit Course Description"
                      className={`${
                        openEditCourse ? '' : 'hidden'
                      } mt-3 h-20 w-3/4 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="About the Course`}
                      onChange={(e) => setCourseDescription(e.target.value)}
                    />
                    {/* <IconButton
                      onClick={(e) => handleEditDescription()}
                      className="w-9 h-9 ml-5 flex justify-center text-indigo-500  hover:text-white hover:bg-indigo-500 font-small focus:outline-none"
                    >
                      {openEditDescription ? (
                        <DoneIcon fontSize="small" />
                      ) : (
                        <EditIcon fontSize="small" />
                      )}
                      <span className="">{openEditTitle ? 'Done' : 'Edit'}</span>
                    </IconButton> */}
                  </div>
                  <button
                    id="lessondetaildropdown"
                    onClick={handleObjectiveDropDown}
                    className="my-3 font-bold w-full inline-flex outline-none items-center text-left text-gray-700 focus:outline-none "
                    type="button"
                  >
                    {isobjectiveOpen ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                    <span className="pl-1">Learning Objective</span>
                  </button>
                  <div
                    className={`flex flex-row justify-evenly mt-1 ${
                      isobjectiveOpen ? '' : 'hidden'
                    }`}
                  >
                    <div className="w-2/5">
                      <div className="flex justify-center items-center">
                        <span className="flex font-bold text-center">
                          What you will learn
                        </span>
                        {/* <IconButton
                          onClick={(e) => handleEditWhatToLearn()}
                          className="w-9 h-9 justify-self-end flex justify-center text-indigo-500  hover:text-white hover:bg-indigo-500 font-small focus:outline-none"
                        >
                          {openEditDescription ? (
                            <DoneIcon fontSize="small" />
                          ) : (
                            <EditIcon fontSize="small" />
                          )}
                          <span className="">{openEditTitle ? 'Done' : 'Edit'}</span>
                        </IconButton> */}
                      </div>
                      <div className="mt-2">
                        <span className={`${openEditCourse ? 'hidden' : ''}`}>
                          {courseWhatToLearn
                            ? courseWhatToLearn
                                .split('\n')
                                .map((item: any, index: any) => {
                                  return (
                                    <span key={index} className="w-full block">
                                      {item}
                                    </span>
                                  );
                                })
                            : course?.WhatToLearn.split('\n').map(
                                (item: any, index: any) => {
                                  return (
                                    <span key={index} className="w-full block">
                                      {item}
                                    </span>
                                  );
                                }
                              )}
                        </span>
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          value={courseWhatToLearn}
                          className={`${
                            openEditCourse ? '' : 'hidden'
                          } h-20 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                          placeholder="What the Student learn from this course"
                          onChange={(e) => setCourseWhatToLearn(e.target.value)}
                          onKeyUp={checkEnterforwhattolearn}
                        />
                      </div>
                    </div>
                    <div className="w-2/5">
                      <div className="flex justify-center items-center">
                        <span className="flex font-bold text-center">
                          Skill TO Gain
                        </span>
                        {/* <IconButton
                          onClick={(e) => handleEditSkillToGain()}
                          className="w-9 h-9 justify-self-end flex justify-center text-indigo-500  hover:text-white hover:bg-indigo-500 font-small focus:outline-none"
                        >
                          {openEditDescription ? (
                            <DoneIcon fontSize="small" />
                          ) : (
                            <EditIcon fontSize="small" />
                          )}
                          <span className="">{openEditTitle ? 'Done' : 'Edit'}</span>
                        </IconButton> */}
                      </div>
                      <div className="mt-2">
                        <span className={`${openEditCourse ? 'hidden' : ''}`}>
                          {courseSkillToGain
                            ? courseSkillToGain
                                .split('\n')
                                .map((item: any, index: any) => {
                                  return (
                                    <span key={index} className="w-full block">
                                      {item}
                                    </span>
                                  );
                                })
                            : course?.SkillToGain.split('\n').map(
                                (item: any, index: any) => {
                                  return (
                                    <span key={index} className="w-full block">
                                      {item}
                                    </span>
                                  );
                                }
                              )}
                        </span>
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          value={courseSkillToGain}
                          className={`${
                            openEditCourse ? '' : 'hidden'
                          } h-20 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                          placeholder="please add Skills that will gained"
                          onChange={(e) => setCourseSkillToGain(e.target.value)}
                          onKeyUp={checkEnterforskilltogain}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divide-y-2 divide-gray-900 divide-solid"></div>
                <div className="pl-5 w-full text-gray-700 text-left">
                  {course?.Lessons.map((lesson: any, index: number) => {
                    return (
                      <Lesson
                        key={index}
                        openEdit={enableEdit}
                        lesson={lesson}
                        callback={LessonCallback}
                      />
                    );
                  })}
                </div>
                {/* {enableEdit && (
                  <div className="px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-1 px-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={savesubmitHandler}
                    >
                      <SaveIcon fontSize="small" /> Save
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailCourse;
