const FooterScreen = () => {
  return (
    <footer className="mt-5 h-16 z-20 p-5 w-full bg-white drop-shadow-[45px_5px_35px_rgba(0,0,0,0.25)] shadow md:flex md:items-center md:justify-between ">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2022{' '}
        <a href="http://samesoft.com" className="hover:underline">
          SameSoft™
        </a>
        . All Rights Reserved.
      </span>
      <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
          <a href="/" className="mr-4 hover:underline md:mr-6 ">
            About
          </a>
        </li>
        <li>
          <a href="/" className="mr-4 hover:underline md:mr-6">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="/" className="mr-4 hover:underline md:mr-6">
            Licensing
          </a>
        </li>
        <li>
          <a href="/" className="hover:underline">
            Contact
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default FooterScreen;
