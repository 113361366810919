import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Axios from 'axios';
import useAuth from '../../../../setup/route-manager/useAuth';

type Props = {
  callback: ({ selected }: { selected: {} }) => void;
  country: null;
};

const CountrySearch = ({ callback, country }: Props) => {
  const { state } = useAuth();
  const { userInfo } = state;
  const [selected, setSelected] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const [search, setSearch] = React.useState();

  const handleChange = (data: any) => {
    setSelected(data);
    callback({ selected: data });
  };

  const handleSearch = (data: any) => {
    setSearch(data);
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const { data } = await Axios.get(`https://restcountries.com/v3.1/all`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });

        setOptions((options: any) =>
          data.map((country: any) => {
            return {
              value: country.name.common,
              label: country.name.common,
            };
          })
        );
      } catch (err) {}
    };
    fetchdata();
    !country && setSelected(null);
  }, [country, search, userInfo.token]);

  return (
    <Select
      autoFocus
      options={options}
      placeholder="Select Countries"
      value={selected}
      onChange={handleChange}
      isSearchable={true}
      inputValue={search}
      className="text-left w-full"
      onInputChange={handleSearch}
      isOptionDisabled={(option) => option.isdisabled}
    />
  );
};

export default CountrySearch;
