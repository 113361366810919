import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../setup/route-manager/useAuth';
import InfoIcon from '@mui/icons-material/Info';

type Props = {};

const IsVerified = (props: Props) => {
  const { dispatch } = useAuth();

  const { newUid } = useParams();
  const [message, setMessage] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(`/api/auth/isVerified/${newUid}`);
        setMessage(data.data);
        setTimeout(() => navigate(`/signin`), 2000);
      } catch (err: any) {
        console.log(err);
        if (err.response.status === 401)
          setMessage(err.response.status + ' ' + err.response.data.error);
        if (err.response.status === 404) navigate('/sdhfgj');
      }
      // console.log(status);
    };

    fetchData();
  }, [dispatch, navigate, newUid]);
  return (
    <article className="container my-24 mx-auto px-4">
      <div
        className="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
        role="alert"
      >
        <InfoIcon
          fontSize="small"
          className="flex-shrink-0 inline w-5 h-5 mr-3"
        />

        <span className="sr-only">Info</span>
        <div>
          <span className="font-medium">Info alert!</span>{' '}
          <span className="text-red-800"> {message}</span>
        </div>
      </div>
      <h1 className="text-6xl capitalize mt-10">Verify Your email</h1>
      <h3 className="text-lg mt-5">
        Hey ,We have sent link to verify your email{' '}
        <a
          className="underline decoration-indigo-500 text-indigo-500"
          href="https://mail.google.com/mail/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
      </h3>
    </article>
  );
};

export default IsVerified;
