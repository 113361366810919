import React from 'react';

type Props = {
  Course: any;
};

const CourseComp = ({ Course }: Props) => {
  return (
    <div className="flex flex-row max-w-full h-56 m-4 rounded-2xl bg-slate-50 drop-shadow-xl">
      <div className="basis-1/4">
        <img
          src={`/${Course.ImageUrl.replace('public', 'localFile')}`}
          alt="courseimage"
          className="w-full h-full rounded-l-2xl"
        />
      </div>
      <div className="px-5 basis-3/4">
        <h1 className="my-2 text-left font-bold text-xl tracking-widest capitalize">
          {Course.title}
        </h1>
        <p className="text-left text-sm tracking-wide indent-10">
          {Course.description}
        </p>
      </div>
      {/* <div className=""></div> */}
    </div>
  );
};

export default CourseComp;
