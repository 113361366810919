import React from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import StarIcon from '@mui/icons-material/Star';

type Props = {
  item: {
    id: number;
    imgUrl: string;
    title: string;
    students: number;
    rating: number;
  };
};

const FreeCourseCard = ({ item }: Props) => {
  return (
    <div
      className={`bg-slate-100 shadow-lg rounded-xl my-10 flex-none basis-1/3 p-4 snap-always snap-center hover:drop-shadow-2xl`}
    >
      <div className="mb-4 relative w-full top-0 left-0 z-30">
        <img src={item.imgUrl} alt="" className="rounded-lg w-full" />
        <button className="absolute -bottom-5 right-5 z-50 rounded-2xl bg-indigo-600 py-2 px-4 text-white hover:bg-indigo-800 font-medium text-sm text-center ">
          Free
        </button>
      </div>
      <div className="mt-5">
        <h2 className="text-left font-semibold leading-10">{item.title}</h2>
        <div className="flex items-center gap-5">
          <div className="flex items-center gap-2">
            <PersonOutlineIcon
              fontSize="small"
              className="text-indigo-500 font-base"
            />
            <span>{item.students}K</span>
          </div>
          <div className="flex items-center gap-2">
            <StarIcon fontSize="small" className="text-indigo-500 font-base" />
            <span>{item.rating}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeCourseCard;
