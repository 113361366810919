import Container from '@mui/material/Container';
import React, { UIEventHandler } from 'react';
import FreeCourseCard from './FreeCourseCard';

type Props = {};

const cdata = [
  {
    id: 1,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    students: 20,
    rating: 10,
  },
  {
    id: 2,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    students: 40,
    rating: 39,
  },
  {
    id: 3,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    students: 200,
    rating: 80,
  },
  {
    id: 4,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    students: 200,
    rating: 80,
  },
  {
    id: 5,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    students: 200,
    rating: 80,
  },
  {
    id: 6,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    students: 200,
    rating: 80,
  },
];

const FreeCourses = (props: Props) => {
  // : UIEventHandler<HTMLDivElement>

  const handleHorizontalScroll = (position: any, previousPosition: any) => {
    const diffScroll = position - previousPosition;
    const direction = diffScroll > 0 ? 'right' : 'left';

    console.log(`Scroll ${direction} to ${position}`);
    // return e.;
  };

  return (
    <section className="my-14">
      <Container>
        <div className="mb-5 flex justify-center items-center">
          <h1 className="font-bold">Our Free Courses</h1>
        </div>
        <div className="w-full px-5 scrollbar-none flex flex-nowrap gap-3 overflow-y-auto snap-x snap-mandatory">
          {cdata.map((course, index) => {
            return <FreeCourseCard key={course.id} item={course} />;
          })}
        </div>
      </Container>
    </section>
  );
};

export default FreeCourses;
