import React from 'react';
import { Link } from 'react-router-dom';
import LandingHeader from '../LandingHeader';
import HeroSection from '../HeroSection/index';
import CompanySection from '../CompanySection';
import AboutUsSection from '../AboutUsSection';
import CoursesSection from '../CoursesSection';
import ChooseUs from '../ChooseUs';
import FeatureSection from '../FeatureSection';
import FreeCourses from '../FreeCourses';
import Testimonial from '../Testimonial';

type Props = {};

const LandingPage = (props: Props) => {
  return (
    <>
      <LandingHeader />
      <HeroSection />
      <CompanySection />
      <AboutUsSection />
      <CoursesSection />
      <ChooseUs />
      <FeatureSection />
      <FreeCourses />
      <Testimonial />
    </>
    // <div className="shadow flex flex-row p-5 bg-white space-x-reverse space-x-4 justify-end">
    //   <Link
    //     to="/signin"
    //     className="order-last drop-shadow-xl inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm uppercase text-white font-semibold hover:outline hover:bg-white hover:text-indigo-600 hover:outline-2 hover:outline-indigo-500 "
    //   >
    //     signin
    //   </Link>
    //   <Link
    //     to="/signup"
    //     className="drop-shadow-xl inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm uppercase text-white font-semibold hover:outline hover:bg-white hover:text-indigo-600 hover:outline-2 hover:outline-indigo-500 "
    //   >
    //     became an Instructor
    //   </Link>
    // </div>
  );
};

export default LandingPage;
