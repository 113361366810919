import React from 'react';

type FormStepsProps = {
  step: number;
};

const FormSteps = ({ step }: FormStepsProps) => {
  return (
    <div className="flex flex-row mb-2 items-center justify-center mx-4 h-9 bg-slate-50 rounded-md">
      <div
        className={`${
          step === 0
            ? 'border-[#063A6C] text-[#063A6C] font-semibold drop-shadow-lg rounded-r-md border-t-2'
            : 'text-gray-700 border-transparent'
        } h-full flex justify-center items-center text-base font-medium grow border-b-2 bg-slate-50 shadow-lg rounded-l-md`}
      >
        <span className="">Create Course</span>
      </div>
      <div
        className={`${
          step === 1
            ? 'border-[#063A6C] text-[#063A6C] font-semibold drop-shadow-lg rounded-r-md border-t-2'
            : 'text-gray-700 border-transparent'
        } h-full flex justify-center items-center text-base font-medium grow border-b-2 bg-slate-50 shadow-lg rounded-r-md`}
      >
        <span className="">Create Lesson</span>
      </div>
    </div>
  );
};

export default FormSteps;
