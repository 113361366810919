import React, { useEffect, useState } from 'react';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { IconButton, Tooltip } from '@mui/material';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import UploadForm from './uploader';
import Axios from 'axios';
import { toast } from 'react-toastify';
import useAuth from '../../../../setup/route-manager/useAuth';
import { getError } from '../../../../setup/util';

enum flag {
  delete = 'Delete',
  edit = 'Edit',
}

type VideoProps = {
  video: any;
  openEdit: boolean;
  callback: ({
    Video,
    flag,
  }: {
    Video: { id: string; URL: string; name: string };
    flag: flag;
  }) => void;
};

const Video = ({ video, openEdit, callback }: VideoProps) => {
  const [videoName, setVideoName] = useState<string>(video?.name || '');
  const [openVideoEdit, setOpenVideoEdit] = useState(false);
  // const [files, setfiles] = useState<File[]>([]);
  const [VideoUrl, setVideoUrl] = useState<string>(video?.URL || '');
  const { state: authstate } = useAuth();
  const { userInfo } = authstate;
  const [open, setOpen] = useState<boolean | undefined>(false);
  // console.log(video);

  const UploaderCallback = ({
    payload,
    VideoUrl,
  }: {
    payload?: boolean;
    VideoUrl?: string;
  }) => {
    if (payload) setOpen(payload);
    if (VideoUrl) setVideoUrl(VideoUrl);
  };

  const replaceHandler = () => {
    setOpen(true);
  };

  const DeleteHandler = async () => {
    try {
      //backend must be implemented
      const data = await Axios.post(
        '/api/course/deleteUpload',
        {
          video,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success('succesfully deleted the video');
      callback({
        Video: { id: video._id, URL: video.URL, name: video.name },
        flag: flag.delete,
      });
    } catch (err: any) {
      // console.log(err);
      toast.error(getError(err));
    }
  };

  const videoEditHandler = () => {
    setOpenVideoEdit(!openVideoEdit);
  };

  const videoDoneHandler = () => {
    setOpenVideoEdit(!openVideoEdit);
    callback({
      Video: { id: video._id, URL: VideoUrl, name: videoName },
      flag: flag.edit,
    });
  };

  return (
    <>
      <div className="flex items-center justify-between ml-7 p-2">
        <div className="flex items-center space-x-5 w-1/3">
          <VideoFileIcon fontSize="medium" className="text-indigo-500" />
          <span
            className={`${
              openVideoEdit ? '' : openEdit ? 'hidden' : ''
            } text-sm font-semibold uppercase`}
          >
            {videoName}
          </span>
          <input
            type="text"
            name="title"
            value={videoName}
            onChange={(e) => setVideoName(e.target.value)}
            placeholder="edit video name"
            className={`${
              openVideoEdit ? 'hidden' : openEdit ? '' : 'hidden'
            } grow h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
          />
        </div>
        {openEdit ? (
          <div className="flex space-x-2 mr-10">
            {!openVideoEdit && (
              <div>
                <Tooltip title="Replace Video">
                  <IconButton
                    className="text-indigo-500 hover:bg-indigo-500 hover:text-white "
                    onClick={(event) => replaceHandler()}
                  >
                    <UpgradeIcon fontSize="small" className="text-inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            <div>
              <Tooltip title="Delete Video">
                <IconButton
                  className="text-red-500 hover:bg-red-500 hover:text-white"
                  onClick={(event) => DeleteHandler()}
                >
                  <DeleteIcon fontSize="small" className="text-inherit" />
                </IconButton>
              </Tooltip>
            </div>

            {openVideoEdit ? (
              <Tooltip title="Edit Name">
                <div>
                  <IconButton
                    className="text-indigo-500 hover:bg-indigo-500 hover:text-white "
                    onClick={(event) => videoEditHandler()}
                  >
                    <EditIcon fontSize="small" className="text-inherit" />
                  </IconButton>
                </div>
              </Tooltip>
            ) : (
              <div>
                <Tooltip title="Done">
                  <IconButton
                    className="text-indigo-500 hover:bg-indigo-500 hover:text-white "
                    onClick={(event) => videoDoneHandler()}
                  >
                    <DoneIcon fontSize="medium" className="text-inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        ) : null}
      </div>
      {open ? (
        <UploadForm dialogTitle="Change Video" callback={UploaderCallback} />
      ) : null}
    </>
  );
};

export default Video;
