import React from 'react';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import StarIcon from '@mui/icons-material/Star';

type CourseCardProps = {
  item: {
    imgUrl: string;
    title: string;
    lesson: number;
    students: number;
    rating: number;
  };
};

const CourseCard = ({ item }: CourseCardProps) => {
  return (
    <div className="bg-slate-100 shadow-lg rounded-xl my-10 flex-none basis-1/4 p-4 snap-always snap-center hover:drop-shadow-2xl">
      <div>
        <img src={item.imgUrl} alt="" className="w-full rounded-md mb-5" />
      </div>
      <div>
        <h2 className="text-left leading-6">{item.title}</h2>
        <div className="pt-2 flex justify-between items-center">
          <p className="font-medium text-sm flex items-center space-x-1">
            <MenuBookIcon
              fontSize="small"
              className="text-indigo-500 font-base"
            />
            <span>{item.lesson} Lessons</span>
          </p>
          <p className="font-medium text-sm flex items-center space-x-1">
            <PersonOutlineIcon
              fontSize="small"
              className="text-indigo-500 font-base"
            />
            <span>{item.students}K </span>
          </p>
        </div>
        <div className="pt-4 flex justify-between items-center">
          <p className="font-medium text-sm flex items-center space-x-1">
            <StarIcon fontSize="small" className="text-indigo-500 font-base" />
            <span>{item.rating}</span>
          </p>
          <p className="flex items-center space-x-1 ">
            <a
              href="#"
              className="text-indigo-500 font-semibold no-underline text-sm"
            >
              Enroll Now
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
