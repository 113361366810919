import React, { useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useNavigate } from 'react-router-dom';

import Axios from 'axios';
import {
  Box,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import FileService from '../../../../service/FileService';
import {
  validateFileSize,
  validateFileType,
} from '../../../../service/FileValidatorService';
import { adminContext } from '../../../../setup/context-manager/admin';
import useAuth from '../../../../setup/route-manager/useAuth';
import FormSteps from '../FormSteps';

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const AddCourse = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>('');
  const [{ description, wordCount }, setdescription] = useState({
    description: '',
    wordCount: 0,
  });
  const [WhatToLearn, setwhatToLearn] = useState<string>('');
  const [SkillToGain, setskillToGian] = useState<string>('');
  const [thumbnailFile, setthumbnailFile] = useState<File | null>(null);
  const [ImageUrl, setImageUrl] = useState<string>('');
  const [uplaodFormError, setuploadFormError] = useState<string>('');
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [requiredError, SetRequiredError] = useState({
    title: '',
    description: '',
    WhatToLearn: '',
    SkillToGain: '',
    ImageUrl: '',
  });
  const { dispatch } = useContext(adminContext);
  const { state: authstate } = useAuth();
  const { userInfo } = authstate;
  const fileTypes = ['JPG', 'PNG', 'JPEG', 'jpeg', 'jpg', 'png'];
  const minWord = 100;
  const maxWord = 150;

  // Axios.defaults.baseURL = 'http://localhost:3000';
  const uploadhandler = async (file: any) => {
    console.log(file);
    try {
      if (!file) {
        return;
      }

      const filesizevalidator = await validateFileSize(1e7, file.size);
      const fileTypevalidator = await validateFileType(
        fileTypes,
        FileService.getFileExtention(file.name)
      );

      console.log("is valid"+filesizevalidator.isvalid+fileTypevalidator.isvalid)
      if (!filesizevalidator.isvalid) {
        setuploadFormError(filesizevalidator.errorMessage);
        toast.error(filesizevalidator.errorMessage);
        // console.log(filesizevalidator.errorMessage);
        setthumbnailFile(null);
      }
      if (!fileTypevalidator.isvalid) {
        setuploadFormError(fileTypevalidator.errorMessage);
        toast.error(fileTypevalidator.errorMessage);
        // console.log(fileTypevalidator.errorMessage);
        setthumbnailFile(null);
      }

      if (
        !uplaodFormError &&
        filesizevalidator.isvalid &&
        fileTypevalidator.isvalid
      ) {
        console.log('----working inside--')
        setuploadFormError('');
        const fileservice = new FileService([file]);

        console.log(fileservice.getFormData())    
        
        console.log(`Bearer ${userInfo.token}`)
        const res = await Axios.post(
          '/api/course/uploadFiles',
          fileservice.getFormData(),
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress(progressEvent) {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadPercentage(percentage);
              setTimeout(() => setUploadPercentage(0), 2000);
            },
          }
        );
        // console.log(res.data.data[0]);
        setImageUrl(res.data.data[0]);
        setthumbnailFile(file);
      }
    } catch (err: any) {
      toast.error(`error : ${err.message}`);
    }
  };

  const DeleteUploadhandler = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    //backend must be implemented
    try {
      const res = await Axios.post(
        '/api/course/deleteUpload',
        {
          ImageUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      // console.log(res);
      setthumbnailFile(null);
      setImageUrl('');
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const nextsubmithandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!title || !description || !WhatToLearn || !SkillToGain || !ImageUrl) {
      SetRequiredError({
        title: !title ? 'Title is required field' : '',
        description: !description
          ? 'Description is required field'
          : wordCount < minWord
          ? `Description has to reach atleast ${minWord} words`
          : '',
        WhatToLearn: !WhatToLearn
          ? 'What students will learn is required field'
          : '',
        SkillToGain: !SkillToGain
          ? 'Skills student gains is required field'
          : '',
        ImageUrl: !ImageUrl ? 'Thumbnail is required field' : '',
      });
    } else {
      dispatch({
        type: 'ADD_COURSE',
        payload: { title, description, WhatToLearn, SkillToGain, ImageUrl },
      });
      navigate('/Instructor/createLessons');
    }
  };

  const checkEnterforwhattolearn = (e: any) => {
    if (e.key === 'Enter') {
      // console.log(String.fromCharCode());
      setwhatToLearn(WhatToLearn + '•' + ' ');
    }
  };

  const checkEnterforskilltogain = (e: any) => {
    if (e.key === 'Enter') {
      // console.log(String.fromCharCode());
      setskillToGian(SkillToGain + '•' + ' ');
    }
  };

  const setFormattedContent = React.useCallback(
    (text: any) => {
      let words = text.split(' ').filter(Boolean);
      if (words.length > maxWord) {
        setdescription({
          description: words.slice(0, maxWord).join(' '),
          wordCount: maxWord,
        });
      }
      // else if (words.length < minWord) {
      //   SetRequiredError({
      //     ...requiredError,
      //     description: !description
      //       ? `Description has to reach atleast ${minWord} words`
      //       : '',
      //   });
      // }
      else {
        setdescription({ description: text, wordCount: words.length });
      }
    },
    [minWord, maxWord, setdescription]
  );

  React.useEffect(() => {
    setFormattedContent(description);
  }, []);

  return (
    <>
      <div className="mt-5 grid grid-cols-3 gap-6">
        <div className=" mt-5 col-span-3 ">
          <FormSteps step={0} />
          <form encType="multipart/form-data" className="px-4">
            <div className="drop-shadow-2xl sm:overflow-hidden sm:rounded-md w-full">
              <div className=" space-y-6 bg-slate-50 px-4 py-5 sm:p-6 ">
                <div className="px-5 grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="courseTitle"
                      className="block text-sm text-left font-medium text-gray-700"
                    >
                      * Course Title
                    </label>
                    <input
                      type="text"
                      name="courseTitle"
                      value={title}
                      required
                      onChange={(e) => setTitle(e.target.value)}
                      className={`${
                        requiredError.title ? 'border-red-500 ring-red-500' : ''
                      } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#063A6C] focus:ring-[#063A6C] sm:text-sm`}
                      placeholder="Course title"
                    />
                    {requiredError.title ? (
                      <span className="text-red-500 flex mt-1 font-semibold ">
                        {requiredError.title}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-span-6 sm:col-span-5">
                    <label
                      htmlFor="about"
                      className="block text-sm text-left font-medium text-gray-700"
                    >
                      * Description
                    </label>
                    <div className="relative mt-1">
                      <textarea
                        id="description"
                        name="description"
                        rows={3}
                        value={description}
                        className={`${
                          requiredError.description
                            ? 'border-red-500 ring-red-500'
                            : ''
                        } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#063A6C] focus:ring-[#063A6C] sm:text-sm`}
                        placeholder="About the Course"
                        onChange={(e) => setFormattedContent(e.target.value)}
                      />
                      <div className="flex absolute bottom-0 right-5 gap-2 opacity-40">
                        <span>
                          min word{' '}
                          <span className="font-bold text-[#063A6C]">
                            {minWord}
                          </span>
                        </span>
                        <span>
                          max word{' '}
                          <span className="font-bold text-[#063A6C]">
                            {maxWord}
                          </span>
                        </span>
                      </div>
                    </div>
                    {requiredError.description ? (
                      <span className="text-red-500 flex mt-1 font-semibold ">
                        {requiredError.description}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="about"
                      className="block text-sm text-left font-medium text-gray-700"
                    >
                      * What To Learn
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        value={WhatToLearn}
                        className={`${
                          requiredError.WhatToLearn
                            ? 'border-red-500 ring-red-500'
                            : ''
                        } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#063A6C] focus:ring-[#063A6C] sm:text-sm`}
                        placeholder="What the Student learn from this course"
                        onChange={(e) => setwhatToLearn(e.target.value)}
                        onClick={(e) => setwhatToLearn('•' + ' ')}
                        onKeyUp={checkEnterforwhattolearn}
                      />
                    </div>
                    {requiredError.WhatToLearn ? (
                      <span className="text-red-500 flex mt-1 font-semibold ">
                        {requiredError.WhatToLearn}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="about"
                      className="block text-sm text-left font-medium text-gray-700"
                    >
                      * Skill To Gain
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        value={SkillToGain}
                        className={`${
                          requiredError.SkillToGain
                            ? 'border-red-500 ring-red-500'
                            : ''
                        } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#063A6C] focus:ring-[#063A6C] sm:text-sm`}
                        placeholder="please add Skills that will gained"
                        onChange={(e) => setskillToGian(e.target.value)}
                        onClick={(e) => setskillToGian('•' + ' ')}
                        onKeyUp={checkEnterforskilltogain}
                      />
                    </div>
                    {requiredError.SkillToGain ? (
                      <span className="text-red-500 flex mt-1 font-semibold ">
                        {requiredError.SkillToGain}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label className="block text-sm text-left font-medium text-gray-700">
                      {thumbnailFile && (
                        <span className="border overflow-hidden bg-gray-200 font-bold rounded-full ">
                          <label className="">{thumbnailFile.name}</label>
                          <IconButton
                            size="small"
                            className="place-self-auto"
                            onClick={DeleteUploadhandler}
                          >
                            <CloseIcon fontSize="small" />
                            {/* <XMarkIcon className="text-gray-700 w-4 h-4" /> */}
                          </IconButton>
                        </span>
                      )}
                    </label>

                    <div
                      className={`${
                        requiredError.ImageUrl
                          ? 'border-red-500'
                          : 'border-[#063A6C]'
                      } mt-1 flex flex-col justify-center rounded-md border-2 border-dashed  px-6 pt-5 pb-2`}
                    >
                      <FileUploader
                        // class="file_upload"
                        className="m-0"
                        handleChange={uploadhandler}
                        name="file-upload"
                        types={fileTypes}
                        multiple={false}
                        fileOrFiles={ImageUrl}
                        children={
                          <div className=" space-y-1 text-center">
                            <InsertPhotoIcon
                              fontSize="large"
                              className="text-[#063A6C]"
                            />
                            {/* <PhotoIcon className="mx-auto text-gray-400 w-12 h-12" /> */}
                            <div className="flex justify-center text-sm text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-medium text-[#063A6C] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#063A6C] focus-within:ring-offset-2 hover:text-[#063A6C]"
                              >
                                <span>* Upload an Image/Thumbnial</span>
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        }
                      />
                      {uploadPercentage !== 0 && (
                        <div className="">
                          <LinearProgressWithLabel value={uploadPercentage} />
                        </div>
                      )}
                    </div>
                    {requiredError.ImageUrl ? (
                      <span className="text-red-500 flex mt-1 font-semibold ">
                        {requiredError.ImageUrl}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="md:col-span-1 bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-[#063A6C] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#063A6C] focus:outline-none focus:ring-2 focus:ring-[#063A6C] focus:ring-offset-2"
                  onClick={nextsubmithandler}
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCourse;
