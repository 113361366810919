import React from 'react';
import VideoFileIcon from '@mui/icons-material/VideoFile';

type VideoProps = {
  video: any;
};

const Video = ({ video }: VideoProps) => {
  // console.log(video);

  return (
    <>
      <div className="flex items-center justify-between ml-7 p-2">
        <div className="flex items-center space-x-5 w-1/3">
          <VideoFileIcon fontSize="medium" className="text-indigo-500" />
          <span className={` text-sm font-semibold uppercase`}>
            {video?.name}
          </span>
        </div>
      </div>
    </>
  );
};

export default Video;
