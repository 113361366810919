import { createContext, useReducer } from 'react';

type clientaction =
  | { type: 'USER_SIGNIN'; payload: any }
  | { type: 'USER_SIGNOUT' }
  | { type: 'COURSE'; payload: any };

type AppState = typeof initialstate;

const initialstate = {
  userInfo: {
    id: localStorage.getItem('id')
      ? JSON.parse(localStorage.getItem('id') || '')
      : '',
    token: localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token') || '')
      : null,
    role: localStorage.getItem('role')
      ? JSON.parse(localStorage.getItem('role') || '')
      : '',
  },
  course: { _id: '' },
};

const reducer = (state: AppState, action: clientaction) => {
  switch (action.type) {
    case 'USER_SIGNIN':
      return {
        ...state,
        userInfo: {
          id: action.payload.id,
          token: action.payload.token,
          role: action.payload.role,
        },
      };
    case 'USER_SIGNOUT':
      return { ...state, userInfo: { id: '', token: null, role: '' } };
    case 'COURSE':
      return { ...state, course: action.payload };
    default:
      return state;
  }
};

interface StoreProviderProps {
  children: React.ReactNode;
}

const storeContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<clientaction>;
}>({ state: initialstate, dispatch: () => {} });

function StoreProvider({ children }: StoreProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialstate);

  return (
    <storeContext.Provider value={{ state, dispatch }}>
      {children}
    </storeContext.Provider>
  );
}

export { storeContext, StoreProvider };
