import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import useAuth from '../../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';
import Search from './Search';
import Review from './Review';

type Props = {};

const SuperAdminCourseReview = (props: Props) => {
  const [reviews, setReviews] = useState<any>();
  const [course, setCourse] = useState<any>(null);
  const { state: authState } = useAuth();
  const { userInfo } = authState;

  const courseCallback = ({ selected }: any) => setCourse(selected);
  // console.log(course);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (course) {
          const { data } = await Axios.get(
            `/api/review/getByCourse/${course.value}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.token}`,
              },
            }
          );
          setReviews(data.data);
        }
      } catch (err) {
        console.log(err);
        toast.error(getError(err));
      }
    };
    fetchData();
  }, [course, userInfo.token]);

  console.log(reviews);

  return (
    <div className="mt-10">
      <div></div>
      <h1 className="px-4 mt-2 text-left font-semibold text-lg capitalize">
        Reviewed Course
      </h1>
      <div className="w-3/5">
        <Search callback={courseCallback} />
      </div>
      <div className="mt-5">
        {reviews?.map((review: any) => {
          return <Review key={review._id} review={review} />;
        })}
      </div>
    </div>
  );
};

export default SuperAdminCourseReview;
