import useAuth from '../route-manager/useAuth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface RequiredAuthProp {
  allowedRole: string;
}

const RequireAuth = ({ allowedRole }: RequiredAuthProp) => {
  const { state } = useAuth();
  const location = useLocation();

  return state?.userInfo.role.includes(allowedRole) ? (
    <Outlet />
  ) : state?.userInfo.token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default RequireAuth;
