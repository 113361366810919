import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import MyLearningScreen from './screen/Student/mylearing';
import ProfileScreen from './screen/Student/profile';
import Layout from './screen/common/Layout';
import Missing from './screen/common/missing';
import SignInScreen from './screen/common/sign-in';
import Unauthorized from './screen/common/Unauthorized';
import RegisterStudent from './screen/Admin/Instructor/AddClient';
import RequireAuth from './setup/auth/RequireAuth';
import SignUpScreen from './screen/common/sign-up';
import NowLearning from './screen/Student/NowLearning';
import IsVerified from './screen/common/Verification';
import LandingPage from './screen/common/LandingPage';
import AddCourse from './screen/Admin/Instructor/AddCourse';
import AssignedCourse from './screen/Admin/Instructor/AssignedCourse';
import ClientList from './screen/Admin/Instructor/ClientList';
import CourseList from './screen/Admin/Instructor/CourseList';
import CourseReview from './screen/Admin/Instructor/CourseReview';
import CreateLessons from './screen/Admin/Instructor/CreateLessons';
import InstructorProfile from './screen/Admin/Instructor/InstructorProfile';
import DetailCourse from './screen/Admin/Instructor/DetailCourse';
import InstructorLayout from './screen/Admin/Instructor/InstructorLayout';
import SuperAdminLayout from './screen/Admin/SuperAdmin/SuperAdminLayout';
import InstructorDashboard from './screen/Admin/Instructor/InstructorDashboard';
import SuperAdminDashboard from './screen/Admin/SuperAdmin/SuperAdminDashboard';
import AllCourse from './screen/Admin/SuperAdmin/AllCourse';
import InstructorCourses from './screen/Admin/SuperAdmin/InstructorCourses';
import AllUsers from './screen/Admin/SuperAdmin/AllUsers';
import Instructors from './screen/Admin/SuperAdmin/Instructors';
import Students from './screen/Admin/SuperAdmin/Students';
import CreateUsers from './screen/Admin/SuperAdmin/CreateUsers';
import ApprovedCountry from './screen/Admin/SuperAdmin/ApprovedCountry';
import StudentCourse from './screen/Admin/SuperAdmin/StudentCourse';
import SuperAdmnDetailCourse from './screen/Admin/SuperAdmin/SuperAdminDetailCourse';
import InstructorCourse from './screen/Admin/SuperAdmin/InstructorCourse';
import SuperAdminProfile from './screen/Admin/SuperAdmin/SuperAdminProfile';
import SuperAdminAllReview from './screen/Admin/SuperAdmin/SuperAdminAllReview';
import SuperAdminCourseReview from './screen/Admin/SuperAdmin/SuperAdminCourseReview';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        {/* <Route path="/review" element={<ReviewForm />} /> */}
        <Route index element={<LandingPage />} />
        <Route path="/signin" element={<SignInScreen />} />
        <Route path="/signout" element={<Navigate to="/signin" replace />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/signup" element={<SignUpScreen />} />
        <Route path="/isVerified/:newUid" element={<IsVerified />} />
        {/* we want to protect these routes */}

        <Route path="/Student" element={<RequireAuth allowedRole="Student" />}>
          <Route index element={<MyLearningScreen />} />
          <Route path="/Student/nowlearning/:id" element={<NowLearning />} />
          <Route path="/Student/profile" element={<ProfileScreen />} />
        </Route>
        <Route element={<RequireAuth allowedRole="Instructor" />}>
          <Route path="/Instructor" element={<InstructorLayout />}>
            <Route index element={<InstructorDashboard />} />
            <Route path="/Instructor/Profile" element={<InstructorProfile />} />
            <Route path="/Instructor/Course" element={<CourseList />} />
            <Route path="/Instructor/User" element={<ClientList />} />
            <Route path="/Instructor/Register" element={<RegisterStudent />} />
            <Route path="/Instructor/addCourse" element={<AddCourse />} />
            <Route
              path="/Instructor/createLessons"
              element={<CreateLessons />}
            />
            <Route
              path="/Instructor/CourseDetail/:id"
              element={<DetailCourse />}
            />
            <Route
              path="/Instructor/UserCourses/:id"
              element={<AssignedCourse />}
            />
            <Route path="/Instructor/Reviews" element={<CourseReview />} />
          </Route>
        </Route>
        <Route element={<RequireAuth allowedRole="SuperAdmin" />}>
          <Route path="/SuperAdmin" element={<SuperAdminLayout />}>
            <Route index element={<SuperAdminDashboard />} />
            <Route path="/SuperAdmin/Profile" element={<SuperAdminProfile />} />
            <Route path="/SuperAdmin/Course" element={<AllCourse />} />
            <Route
              path="/SuperAdmin/Instructor/Course"
              element={<InstructorCourses />}
            />
            <Route path="/SuperAdmin/AllUsers" element={<AllUsers />} />
            <Route path="/SuperAdmin/Instructors" element={<Instructors />} />
            <Route path="/SuperAdmin/Students" element={<Students />} />

            <Route path="/SuperAdmin/Register" element={<CreateUsers />} />
            <Route path="/SuperAdmin/Country" element={<ApprovedCountry />} />
            {/* <Route path="/SuperAdmin/addCourse" element={<CreateUsers />} /> */}
            {/* <Route
              path="/SuperAdmin/createLessons"
              element={<CreateLessons />}
            /> */}
            <Route
              path="/SuperAdmin/CourseDetail/:id"
              element={<SuperAdmnDetailCourse />}
            />
            <Route
              path="/SuperAdmin/StudentCourses/:id"
              element={<StudentCourse />}
            />
            <Route
              path="/SuperAdmin/InstructorCourses/:id"
              element={<InstructorCourse />}
            />
            <Route
              path="/SuperAdmin/Reviews"
              element={<SuperAdminAllReview />}
            />
            <Route
              path="/SuperAdmin/CourseReviews"
              element={<SuperAdminCourseReview />}
            />
          </Route>
        </Route>
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
