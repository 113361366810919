import { Fragment, useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Axios from 'axios';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../../../setup/route-manager/useAuth';
import { userInfo } from 'os';
import { toast } from 'react-toastify';
import { getError } from '../../../setup/util';
import ReactTimeAgo from 'react-time-ago';

const navigation = [
  { name: 'My learning', href: '/Student', current: true },
  // { name: 'Categories', href: '#', current: false },
  // { name: 'Projects', href: '#', current: false },
  // { name: 'Calendar', href: '#', current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
type HeaderScreenProps = {};

const HeaderScreen = (props: HeaderScreenProps) => {
  const [getUser, setGetUser] = useState<any>();
  const { state, dispatch } = useAuth();
  const { userInfo } = state;
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);

  // console.log(location);

  const signoutHandler = async () => {
    try {
      await Axios.get('/api/auth/logout');
      dispatch({ type: 'USER_SIGNOUT' });
      localStorage.removeItem('token');
      localStorage.removeItem('role');
    } catch (err: any) {
      toast.error(err);
    }
  };

  useEffect(() => {
    location.pathname !== navigation[0].href
      ? (navigation[0].current = false)
      : (navigation[0].current = true);
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get('/api/user/currentUser', {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setGetUser(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchData();
    const fetchNotification = async () => {
      const { data } = await Axios.get('/api/notify/', {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setNotifications(data.data);
    };
    fetchNotification();
  }, [userInfo.token]);

  return (
    <Disclosure
      as="nav"
      className="bg-white drop-shadow-xl w-full relative z-50"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="ml-10 flex p-2 items-center border border-slate-200 rounded-full bg-white shadow-xl text-indigo-500 text-md hover:bg-indigo-500 hover:text-white hover:shadow-xl">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <MenuIcon fontSize="small" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src="/samesoft.png"
                    alt="Same Soft Online Learning"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src="/samesoft.png"
                    alt="Same Soft Online Learning"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-white text-indigo-500 '
                            : 'text-white bg-indigo-500 hover:bg-indigo-700 hover:text-white',
                          'px-3 py-2 rounded-md text-base font-semibold'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              {/* login information */}

              {state.userInfo ? (
                <div className=" p-1 flex space-x-4 justify-end absolute inset-y-0 right-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <Menu as="div" className="relative ml-3">
                    <div className="flex items-center relative text-gray-500 dark:text-white text-md">
                      <Menu.Button className="inline-flex relative p-2 items-center border border-slate-200 rounded-full bg-white shadow-xl text-indigo-600 text-md hover:bg-indigo-500 hover:text-white hover:shadow-xl">
                        <span className="sr-only">Open Notification</span>
                        <div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                          {
                            notifications.filter(
                              (not: any) => not.status === 'unread'
                            ).length
                          }
                        </div>
                        <NotificationsIcon fontSize="small" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                      // className="relative"
                    >
                      <Menu.Items className="w-80 absolute right-0 mt-4 bg-white rounded-md shadow-lg overflow-hidden origin-top-right ring-1 ring-black ring-opacity-5 transform opacity-100 focus:outline-none">
                        <div className="relative z-50 w-full max-h-80 overflow-x-hidden overflow-y-scroll scrollbar-thin scrollbar-thumb-indigo-100 scrollbar-track-gray-100">
                          {notifications.map((notification: any, index) => {
                            return (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <Link
                                    to={`#`}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-indigo-500'
                                        : '',
                                      ' text-sm text-gray-700 flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2'
                                    )}
                                  >
                                    <img
                                      className="h-8 w-8 rounded-full object-cover mx-1 "
                                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                                      alt="avatar"
                                    />
                                    <p className="text-gray-600 text-sm mx-2">
                                      <span className="font-bold">
                                        {notification.creator.name}
                                      </span>{' '}
                                      {notification?.title}{' '}
                                      <span className="font-bold text-indigo-500">
                                        {notification.feedbackObj?.title}
                                      </span>{' '}
                                      <ReactTimeAgo
                                        date={new Date(notification.createdAt)}
                                        locale="en-US"
                                      />
                                    </p>
                                  </Link>
                                )}
                              </Menu.Item>
                            );
                          })}
                        </div>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              // to={`#`}
                              className={classNames(
                                active ? 'bg-gray-100 text-indigo-500' : '',
                                'block bg-gray-800 text-white text-center font-bold py-2 hover:bg-indigo-500'
                              )}
                            >
                              {' '}
                              See all notifications
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <span className="w-1 h-8 rounded-lg bg-indigo-500"></span>
                  {/* Profile dropdown  */}
                  {/* <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src="/profile.jpg"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/profile"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Setting
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/signout"
                              onClick={signoutHandler}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Sign out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu> */}
                  <Menu as="div" className="relative ml-3 ">
                    <div className="flex items-center relative text-gray-500 text-md">
                      <span className="text-left text-base pr-2 font-bold capitalize">
                        {getUser?.name}
                      </span>
                      <Menu.Button className="flex rounded-full bg-indigo-600 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-9 w-9 rounded-full shadow-xl"
                          src={`/${getUser?.profileImageUrl?.replace(
                            'public',
                            'localFile'
                          )}`}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-30 mt-4 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/Student/profile`}
                              className={classNames(
                                active ? 'bg-gray-100 text-indigo-500' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Profile
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/signout"
                              onClick={signoutHandler}
                              className={classNames(
                                active ? 'bg-gray-100 text-indigo-500' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Sign out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              ) : (
                <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                  <Link
                    to="/signin"
                    className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                  >
                    Sign in
                  </Link>
                </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-white text-indigo-500 '
                      : 'text-white bg-indigo-500 hover:bg-indigo-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-semibold'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default HeaderScreen;
