import React from 'react';
import Container from '@mui/material/Container';
import CountUp from 'react-countup';

type Props = {};

const AboutUsSection = (props: Props) => {
  return (
    <section id="aboutUs">
      <Container>
        <div className="grid grid-rows-1">
          <div className="my-14 grid md:grid-cols-2 lg:grid-cols-2 gap-5">
            <div className="col-span-1 drop-shadow-2xl">
              <img
                src="/aboutus.jpg"
                alt=""
                className="w-full h-full rounded-xl"
              />
            </div>
            <div className="col-span-1 p-5">
              <h1 className="text-left mb-4 font-semibold leading-10">
                About Us
              </h1>
              <p className="mb-4 text-left leading-10">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Cumque, et unde delectus facilis quo architecto corrupti
                voluptatum aperiam hic provident dolores molestiae officiis
                adipisci consequuntur?
              </p>
              <div>
                <div className="flex space-x-5 items-center">
                  <div className="mb-4 text-left leading-10">
                    <span className="font-semibold text-3xl text-indigo-500">
                      <CountUp start={0} end={25} duration={2} suffix="K" />
                    </span>
                    <p className="text-base text-gray-500 font-medium">
                      Completed Projects
                    </p>
                  </div>
                  <div className="mb-4 text-left leading-10">
                    <span className="font-semibold text-3xl text-indigo-500">
                      <CountUp start={0} end={12} duration={2} suffix="M" />
                    </span>
                    <p className="text-base text-gray-500 font-medium">
                      Patient Around World
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 items-center">
                  <div className="mb-4 text-left leading-10">
                    <span className="font-semibold text-3xl text-indigo-500">
                      <CountUp start={0} end={25} duration={2} suffix="K" />
                    </span>
                    <p className="text-base text-gray-500 font-medium">
                      Completed Projects
                    </p>
                  </div>
                  <div className="mb-4 text-left leading-10">
                    <span className="font-semibold text-3xl text-indigo-500">
                      <CountUp start={0} end={12} duration={2} suffix="M" />
                    </span>
                    <p className="text-base text-gray-500 font-medium">
                      Patient Around World
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutUsSection;
