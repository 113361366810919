import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import CourseComp from './course';
import InfoIcon from '@mui/icons-material/Info';
import useAuth from '../../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';

type Props = {};

const InstructorCourse = (props: Props) => {
  const { id } = useParams();
  const { state } = useAuth();
  const { userInfo } = state;
  const [courses, setCourses] = useState<any[]>([]);
  const [user, setUser] = useState<any>();

  // console.log(user);
  // console.log(courses);

  useEffect(() => {
    const fetchData = async () => {
      (async () => {
        try {
          const { data } = await Axios.get(`/api/user/${id}`, {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          });
          setUser(data.data);
        } catch (err) {
          toast.error(getError(err));
        }
      })();
      (async () => {
        try {
          const { data } = await Axios.get(`/api/course/?Instructor=${id}`, {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          });
          // console.log(data);
          setCourses(data.data.data);
        } catch (err) {
          toast.error(getError(err));
        }
      })();
    };
    fetchData();
  }, [id, userInfo.token]);

  return (
    <div className="my-10">
      <div className="text-left pl-10 font-bold tracking-widest text-xl">
        Created Courses of Instructor {user?.name}
      </div>
      {!courses.length ? (
        <div
          className="flex my-5 p-4 mb-4 justify-center items-center text-lg text-blue-700 rounded-lg "
          role="alert"
        >
          <InfoIcon fontSize="small" />
          <span className="sr-only">Info</span>
          <div className="">
            <span className="font-semibold">Info alert!</span>
            <span className="text-red-500">
              This Instructor not created any courses yet.
            </span>
          </div>
        </div>
      ) : (
        // <div className="my-10">No Assigned Courses</div>
        courses.map((course: any, index: React.Key | null | undefined) => {
          return (
            <Link to={`../CourseDetail/${course._id}`}>
              <CourseComp key={index} Course={course} />
            </Link>
          );
        })
      )}
    </div>
  );
};

export default InstructorCourse;
