import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import useAuth from '../../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';
import Review from './Review';

type Props = {};

const SuperAdminAllReview = (props: Props) => {
  const [reviews, setReviews] = useState<any>();
  const { state: authState } = useAuth();
  const { userInfo } = authState;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(`/api/review/`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        console.log(data.data);
        setReviews(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchData();
  }, [userInfo.token]);

  // console.log(state);

  
  return (
    <div className="mt-10">
      <div></div>
      <h1 className="px-4 mt-2 text-left font-semibold text-lg capitalize">
        Reviewed Course
      </h1>
      <div className="my-5">
        {reviews?.map((review: any) => {
          return <Review review={review} />;
        })}
      </div>
    </div>
  );
};

export default SuperAdminAllReview;
