import Axios from 'axios';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import CustomDialog from './CustomDialog';
import FileService from '../../../../service/FileService';
import {
  validateFileSize,
  validateFileType,
} from '../../../../service/FileValidatorService';
import useAuth from '../../../../setup/route-manager/useAuth';

type UploadFormProps = {
  dialogTitle: string;
  callback: ({
    payload,
    VideoUrl,
  }: {
    VideoUrl?: string;
    payload?: boolean;
  }) => void;
};

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const UploadForm = ({ dialogTitle, callback }: UploadFormProps) => {
  const { state: authstate } = useAuth();
  const { userInfo } = authstate;
  const [VideoUrl, setVideoUrl] = useState<string>('');
  const [hidden, sethidden] = useState(false);
  const [uplaodFormError, setuploadFormError] = useState<string>('');
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [files, setfiles] = useState<File | null>(null);
  const [open, setOpen] = useState(true);

  const Dialogcallback = (payload: boolean) => {
    setOpen(payload);
    callback({ payload: payload });
  };

  const fileTypes = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'swf',
    'mkv',
  ];

  const uploadhandler = async (file: File) => {
    try {
      sethidden(true);
      if (!file) {
        return;
      }
      // console.log(file);
      const filesizevalidator = await validateFileSize(2e9, file.size);
      const fileTypevalidator = await validateFileType(
        fileTypes,
        FileService.getFileExtention(file.name)
      );

      if (!filesizevalidator.isvalid) {
        setuploadFormError(filesizevalidator.errorMessage);
        console.log(filesizevalidator.errorMessage);
        setfiles(null);
      }

      if (!fileTypevalidator.isvalid) {
        setuploadFormError(fileTypevalidator.errorMessage);
        console.log(fileTypevalidator.errorMessage);
        setfiles(null);
      }
      if (
        !uplaodFormError &&
        filesizevalidator.isvalid &&
        fileTypevalidator.isvalid
      ) {
        const fileservice = new FileService([file]);
        setfiles(file);
        const res = await Axios.post(
          '/api/course/uploadFiles',
          fileservice.getFormData(),
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress(progressEvent) {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadPercentage(percentage);
              setTimeout(() => setUploadPercentage(0), 2000);
            },
          }
        );
        // console.log(res.data.data[0]);
        setVideoUrl(res.data.data[0]);
        callback({ VideoUrl: res.data.data[0] });
      }
      setuploadFormError('');
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handleprogress = () => sethidden(true);

  return (
    <CustomDialog
      dialogTitle={dialogTitle}
      openDialog={open}
      callback={Dialogcallback}
    >
      <div className="mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-indigo-500 px-6 pt-5 pb-6 bg-white drop-shadow-xl">
        <FileUploader
          // class="file_upload"
          className="m-0"
          onClick={(e: any) => handleprogress()}
          handleChange={uploadhandler}
          name="file-upload"
          types={fileTypes}
          multiple={false}
          file={files}
          children={
            <div className="space-y-1 text-center">
              <VideoCameraFrontIcon fontSize="small" />
              {/* <VideoCameraIcon className="mx-auto text-gray-400 w-12 h-12" /> */}
              <div className="text-center text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className=" relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>* Upload an Image/Thumbnial</span>
                </label>
              </div>
              {uplaodFormError && <p>{uplaodFormError}</p>}
              <p className="text-center text-xs text-gray-500">
                MP4, MOV, WMV, AVI, AVCHD, FLV, F4V, SWF, MKV up to 2GB
              </p>
            </div>
          }
        />
      </div>
      <div>
        <div
          className=" mt-3 flex items-center bg-indigo-500 text-white text-sm font-bold px-4 py-3 rounded-lg"
          role="alert"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
          </svg>
          <p>The Name Of the video will be saved</p>
        </div>
        {files ? (
          <div className="mt-3 flex flex-wrap p-1 items-center bg-white border text-sm font-bold text-left text-indigo-500 rounded-full drop-shadow-xl">
            <span className="basis-full pl-5 uppercase ">{files.name}</span>
            {hidden && (
              <div className="basis-full mx-5 pl-4">
                <LinearProgressWithLabel value={uploadPercentage} />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </CustomDialog>
  );
};

export default UploadForm;
