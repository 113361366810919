interface ValidatorResponse {
  isvalid: boolean;
  errorMessage: string;
}

// const fileTypes = ['mp4', 'png'];

async function validateFileSize(
  vaildfileSize: number,
  fileSize: Number
): Promise<ValidatorResponse> {
  const FileSizeValidator = await (
    await import('../setup/Validator/FileSizeValidator')
  ).default;
  const validator = new FileSizeValidator(fileSize, vaildfileSize);
  const isvalid = validator.validateFileSize();

  return { isvalid, errorMessage: isvalid ? '' : validator.getErrorMessage() };
}

async function validateFileType(
  validfileTypes: string[],
  fileType: string
): Promise<ValidatorResponse> {
  const filetypevalidator = (
    await import('../setup/Validator/FileTypeValidator')
  ).default;

  const validator = new filetypevalidator(fileType, validfileTypes);
  const isvalid = validator.validateFileType();

  return { isvalid, errorMessage: isvalid ? '' : validator.getErrorMessage() };
}

export { validateFileSize, validateFileType };
