import React from 'react';
import Container from '@mui/material/Container';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AppleIcon from '@mui/icons-material/Apple';
import PinterestIcon from '@mui/icons-material/Pinterest';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';

type Props = {};

const CompanySection = (props: Props) => {
  return (
    <section>
      <Container>
        <div className="my-14 grid grid-rows-1">
          <div className="grid lg:grid-cols-6 md:grid-cols-4">
            <div className="col-span-1 flex items-center space-x-2">
              <InstagramIcon fontSize="medium" className="text-indigo-500" />
              <span className="text-base font-bold">Instragram</span>
            </div>
            <div className="col-span-1 flex items-center space-x-2">
              <LinkedInIcon fontSize="medium" className="text-indigo-500" />
              <span className="text-base font-bold">LinkedIn</span>
            </div>
            <div className="col-span-1 flex items-center space-x-2">
              <AppleIcon fontSize="medium" className="text-indigo-500" />
              <span className="text-base font-bold">Apple</span>
            </div>
            <div className="col-span-1 flex items-center space-x-2">
              <PinterestIcon fontSize="medium" className="text-indigo-500" />
              <span className="text-base font-bold">Pinterest</span>
            </div>
            <div className="col-span-1 flex items-center space-x-2">
              <FacebookIcon fontSize="medium" className="text-indigo-500" />
              <span className="text-base font-bold">Facebook</span>
            </div>
            <div className="col-span-1 flex items-center space-x-2">
              <GoogleIcon fontSize="medium" className="text-indigo-500" />
              <span className="text-base font-bold">Google</span>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CompanySection;
