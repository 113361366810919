import Container from '@mui/material/Container';
import React from 'react';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import StarIcon from '@mui/icons-material/Star';
import CourseCard from './CourseCard';

type Props = {};

const cdata = [
  {
    id: 1,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    lesson: 12,
    students: 20,
    rating: 10,
  },
  {
    id: 2,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    lesson: 16,
    students: 40,
    rating: 39,
  },
  {
    id: 3,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    lesson: 20,
    students: 200,
    rating: 80,
  },
  {
    id: 4,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    lesson: 20,
    students: 200,
    rating: 80,
  },
  {
    id: 5,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    lesson: 20,
    students: 200,
    rating: 80,
  },
  {
    id: 6,
    title: 'sdffsd',
    imgUrl: '/closeup.jpg',
    lesson: 20,
    students: 200,
    rating: 80,
  },
];

const CoursesSection = (props: Props) => {
  return (
    <section id="courses" className="my-14">
      <Container>
        <div className="mb-5 flex justify-between items-center">
          <div className="basis-11/12">
            <h1 className="text-left">Our Popular Courses</h1>
            <p className="text-left">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Quibusdam ipsam labore in velit, consequuntur accusamus quam
              reiciendis. Reiciendis tempore quis consectetur atque. Corrupti
              dolorum earum sapiente labore iusto non asperiores!
            </p>
          </div>
          <div className="grow self-end">
            <button className="mb-3.5 w-full p-1 rounded-md bg-indigo-500 text-white hover:bg-indigo-800">
              See All
            </button>
          </div>
        </div>
        <div className="px-5 scrollbar-none flex flex-nowrap gap-3 overflow-x-auto snap-x snap-mandatory">
          {cdata.map((course) => {
            return <CourseCard key={course.id} item={course} />;
          })}
        </div>
      </Container>
    </section>
  );
};

export default CoursesSection;
