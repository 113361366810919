import React, { SyntheticEvent, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../setup/util';

const SignUpScreen = () => {
  const { dispatch } = useAuth();
  const [fullname, setFullName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [country, setCountry] = useState<string>('');
  const [password, setpassword] = useState<string>();
  const [checkbox, setCheckbox] = useState(false);
  const [countryOption, setCountryOption] = useState<any>(null);

  const navigate = useNavigate();

  const SubmitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post('/api/auth/register', {
        name: fullname,
        email: email,
        // password: password,
      });
      console.log(data);
      const { uid } = data;
      //navigate(`/isVerified/${uid}`);
      navigate('../signin');
      toast.success('You are registered successfully.');
    } catch (err) {
      console.log(err);
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const { data } = await Axios.get(`/api/user/countries`, {
          // headers: {
          //   Authorization: `Bearer ${userInfo.token}`,
          // },
        });
        setCountryOption(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchdata();
  }, []);

  return (
    <div className="bg-gray-100 flex max-h-screen items-center justify-between py-6 px-4 sm:px-6 lg:px-8 overflow-scroll scrollbar-thin scrollbar-thumb-indigo-100 scrollbar-track-gray-100">
      <div className="w-1/2 pb-10">
        <img
          src="/teaching.png"
          alt="bg"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="w-1/2">
        <div className="bg-white drop-shadow-xl border border-gray-200 rounded-lg w-full p-4 sm:p-6 lg:p-8 ">
          <form
            onSubmit={SubmitHandler}
            className="space-y-6"
            action="#"
            method="POST"
          >
            <div className="mx-5 px-10 grid grid-cols-2 gap-5">
              <div className="col-span-2">
                <img
                  className="mx-auto h-12 w-auto"
                  src="/samesoft.png"
                  alt="same Soft"
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                  SignUp to be an Instructor
                </h2>
              </div>

              {/* <div className="btn-wrapper text-center">
                <button
                  className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                  type="button"
                >
                  <img
                    alt="..."
                    className="w-5 mr-1"
                    src="https://demos.creative-tim.com/notus-js/assets/img/github.svg"
                  />
                  Github{' '}
                </button>
                <button
                  className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                  type="button"
                >
                  <img
                    alt="..."
                    className="w-5 mr-1"
                    src="https://demos.creative-tim.com/notus-js/assets/img/google.svg"
                  />
                  Google
                </button>
              </div>
              <div className="text-blueGray-400 text-center mb-3 font-bold">
                <small>Or sign up with credentials</small>
              </div> */}
              {/* <input type="hidden" name="remember" defaultValue="true" /> */}
              <div className="col-span-2">
                <label
                  htmlFor="email-address"
                  className="text-left text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                >
                  Full Name
                </label>
                <input
                  id="full-name"
                  name="name"
                  type="text"
                  value={fullname}
                  onChange={(e) => setFullName(e.target.value)}
                  autoComplete="full-name"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="your name"
                />
              </div>

              <div className="col-span-2">
                <label
                  htmlFor="email-address"
                  className="text-left text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="name@company.com"
                />
              </div>
              {/* <div className="col-span-1">
                  <label
                    htmlFor="password"
                    className="text-left text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    autoComplete="current-password"
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Password"
                  />
                </div> */}
              <div className="col-span-2">
                <label
                  htmlFor="country"
                  className="text-left block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  autoComplete="country-name"
                  value={country}
                  required
                  onChange={(e) => setCountry(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder=""
                >
                  <option selected disabled>
                    choose ur country
                  </option>
                  {countryOption?.data.map((cou: any) => (
                    <option value={cou._id} key={cou._id}>
                      {cou.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="col-span-1">
                <label
                  htmlFor="street-address"
                  className="text-left block text-sm font-medium text-gray-700"
                >
                  Street address
                </label>
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  value={StreetAddress}
                  onChange={(e) => setstreetAddress(e.target.value)}
                  autoComplete="street-address"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="street Number"
                />
                <AddressForm apikey="test_08ff553100c78f06e1d4bfbda89d3177542" />
              </div> */}

              {/* <div className="col-span-1 ">
                <label
                  htmlFor="city"
                  className="block text-left text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={City}
                  onChange={(e) => setcity(e.target.value)}
                  autoComplete="address-level2"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Name of the city"
                />
              </div> */}

              {/* <div className="col-span-1">
                <label
                  htmlFor="region"
                  className="block text-left text-sm font-medium text-gray-700"
                >
                  State / Province
                </label>
                <input
                  type="text"
                  name="region"
                  id="region"
                  // value={Province}
                  // onChange={(e) => setprovince(e.target.value)}
                  autoComplete="address-level1"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="province"
                />
              </div> */}

              {/* <div className="col-span-1">
                  <label
                    htmlFor="postal-code"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    ZIP / Postal code
                  </label>
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    // value={ZipCode}
                    // onChange={(e) => setzipcode(e.target.value)}
                    autoComplete="postal-code"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div> */}
            </div>

            <div className="mx-5 px-10 flex flex-col ">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="privacy-policy"
                  name="privacy-policy"
                  checked={checkbox}
                  onChange={(e) => setCheckbox(!checkbox)}
                  className="border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded "
                />

                <label
                  htmlFor="privacy-policy"
                  className="font-medium ml-2 block text-sm text-gray-900 dark:text-gray-300"
                >
                  I agree with the{' '}
                  <a
                    href="/"
                    className="text-blue-700 ml-auto dark:text-blue-500"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>

              <div className="flex justify-center my-2">
                <button
                  type="submit"
                  className="w-full group relative flex justify-center  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  disabled={checkbox ? !checkbox : !checkbox}
                >
                  Sign Up
                </button>
              </div>
              <div className="text-sm font-medium text-gray-500">
                Already have an account?{' '}
                <a href="/signin" className="text-blue-700 hover:underline">
                  Sign In
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;
