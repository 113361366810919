import React, { useEffect, useState } from 'react';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import Axios from 'axios';
import useAuth from '../../../../setup/route-manager/useAuth';

type Props = {};

const data = [
  {
    name: 'Facebook',
    value: 11111111111,
  },
  {
    name: 'Instagram',
    value: 22222222222,
  },
  {
    name: 'Tiktok',
    value: 33333333333,
  },
  {
    name: 'Tiwtter',
    value: 44444444444,
  },
];

const SuperAdminDashboard = (props: Props) => {
  const { state, dispatch } = useAuth();
  const { userInfo } = state;
  const [numOfAllCourses, setNumOfAllCourses] = useState();
  const [numOfInstructor, setNumOfInstructor] = useState();
  const [numOfStudent, setNumOfStudent] = useState();
  const [numOfAssignedUser, setNumOfAssignedUser] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const courseRes = await Axios.get('/api/course/', {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setNumOfAllCourses(courseRes.data.data.count);
        const StudentRes = await Axios.get('/api/user/?role=Student', {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        // console.log(StudentRes);
        setNumOfStudent(StudentRes.data.data.count);
        const InstructorRes = await Axios.get('/api/user/?role=Instructor', {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setNumOfInstructor(InstructorRes.data.data.count);
        const AssignedRes = await Axios.get('/api/user/assigned', {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setNumOfAssignedUser(AssignedRes.data.data);
      } catch (err) {
        // console.log(err);
      }
    };
    fetchData();
  }, [userInfo.token]);
  return (
    <div className="mt-10">
      <h1 className="text-left pl-4 font-semibold text-lg ">Dashboard</h1>
      <div className="mt-5 px-4 h-52 flex flex-row space-x-5">
        <div className="flex flex-col items-center grow bg-slate-50 drop-shadow-xl rounded-2xl p-6">
          <div className="w-16 h-16 ">
            <PersonIcon
              fontSize="large"
              className="w-full h-full text-sky-500 rounded-full "
            />
          </div>
          <div className="pt-2 text-sm font-light">Total</div>
          <h1 className="pt-1 text-xl font-bold text-sky-500">Instrutors</h1>
          <div className="pt-1.5 text-xl text-sky-500 font-bold">
            {numOfInstructor}
          </div>
        </div>
        <div className="flex flex-col items-center grow bg-slate-50 drop-shadow-xl rounded-2xl p-6">
          <div className="w-16 h-16 ">
            <SchoolIcon
              fontSize="large"
              className="w-full h-full text-lime-500 rounded-full "
            />
          </div>
          <div className="pt-2 text-sm font-light">Total</div>
          <h1 className="pt-1 text-xl font-bold text-lime-500">Courses</h1>
          <div className="pt-1.5 text-xl text-lime-500 font-bold">
            {numOfAllCourses}
          </div>
        </div>
        <div className="flex flex-col items-center grow bg-slate-50 drop-shadow-xl rounded-2xl p-6">
          <div className="w-16 h-16 ">
            <PersonIcon
              fontSize="large"
              className="w-full h-full text-sky-500 rounded-full "
            />
          </div>
          <div className="pt-2 text-sm font-light">Total</div>
          <h1 className="pt-1 text-xl font-bold text-sky-500">Students</h1>
          <div className="pt-1.5 text-xl text-sky-500 font-bold">
            {numOfStudent}
          </div>
        </div>

        <div className="flex flex-col items-center grow bg-slate-50 drop-shadow-xl rounded-2xl p-6">
          <div className="w-16 h-16 ">
            <SchoolIcon
              fontSize="large"
              className="w-full h-full text-emerald-500 rounded-full "
            />
          </div>
          <div className="pt-2 text-sm font-light">Students Assigned To</div>
          <h1 className="pt-1 text-xl font-bold text-emerald-500"> Course</h1>
          <div className="pt-1.5 text-xl text-emerald-500 font-bold">
            {numOfAssignedUser}
          </div>
        </div>
        {/* <div className="flex flex-col items-center grow bg-white drop-shadow-xl rounded-2xl p-6">
          <div className="w-16 h-16 ">
            <SchoolIcon
              fontSize="large"
              className="w-full h-full text-fuchsia-500 rounded-full "
            />
          </div>
          <div className="pt-2 text-sm font-light">Total Course Completed </div>
          <h1 className="pt-1 text-xl font-bold text-fuchsia-500">User</h1>
          <div className="pt-1.5 text-xl text-fuchsia-500 font-bold">10</div>
        </div> */}
      </div>
      {/* <div className="mt-5 mx-5 flex flex-row justify-center space-x-5 rounded-lg bg-white drop-shadow-2xl">
        <div className="basis-1/2  h-80">
          <ResponsiveContainer>
            <PieChart width={400} height={400}>
              <Pie
                dataKey="value"
                isAnimationActive={true}
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              />
              <Pie
                dataKey="value"
                data={data02}
                cx={500}
                cy={200}
                innerRadius={40}
                outerRadius={80}
                fill="#82ca9d"
              />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="basis-1/2">
          <ResponsiveContainer>
            <PieChart width={400} height={400}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data2}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              />
              <Pie
                dataKey="value"
                data={data02}
                cx={500}
                cy={200}
                innerRadius={40}
                outerRadius={80}
                fill="#82ca9d"
              />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div> */}
    </div>
  );
};

export default SuperAdminDashboard;
