import { createContext, useReducer } from 'react';

type adminAction =
  | { type: 'ADD_COURSE'; payload: any }
  | { type: 'Add_LESSON'; payload: any };

type AppState = typeof initialstate;

const initialstate = {
  createCourse: {
    Lessons: [
      {
        fileUrl: [] as any[],
      },
    ] as any[],
  },
};

const reducer = (state: AppState, action: adminAction) => {
  switch (action.type) {
    case 'ADD_COURSE':
      return {
        ...state,
        createCourse: action.payload,
      };
    case 'Add_LESSON':
      return {
        ...state,
        createCourse: {
          ...state.createCourse,
          Lessons: action.payload,
        },
      };
    default:
      return state;
  }
};

interface StoreProviderProps {
  children: React.ReactNode;
}

const adminContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<adminAction>;
}>({ state: initialstate, dispatch: () => {} });

function AdminProvider({ children }: StoreProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialstate);

  return (
    <adminContext.Provider value={{ state, dispatch }}>
      {children}
    </adminContext.Provider>
  );
}

export { adminContext, AdminProvider };
