import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import React from 'react';

interface AddListprops {
  files?: File[];
  description: string | undefined;
  lessonTitle: string | undefined;
}

const AddList = ({ files, description, lessonTitle }: AddListprops) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      className="pb-2"
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary
        expandIcon={
          <KeyboardArrowDown fontSize="small" />
          // <ChevronDownIcon className="w-3 h-3" />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography
          className="text-left"
          variant="subtitle1"
          sx={{ width: '100%', flexShrink: 0 }}
        >
          Lesson Title: {lessonTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" className="text-left truncate ...">
          {description}
        </Typography>
        <List>
          {files?.map((file) => {
            return (
              <ListItem key={file.size} disablePadding>
                <ListItemButton>
                  <img
                    alt=""
                    className="w-20 h-12"
                    src="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg"
                    srcSet="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg 1x, https://img-c.udemycdn.com/course/480x270/1513876_9dfb_2.jpg 2x"
                  />
                  <Typography className="pl-3">{file.name}</Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
          {/* <ListItem disablePadding>
            <ListItemButton>
              <img
                alt=""
                className="w-20 h-12"
                src="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg"
                srcSet="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg 1x, https://img-c.udemycdn.com/course/480x270/1513876_9dfb_2.jpg 2x"
              />
              <Typography className="pl-3">Video name</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <img
                alt=""
                className="w-20 h-12"
                src="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg"
                srcSet="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg 1x, https://img-c.udemycdn.com/course/480x270/1513876_9dfb_2.jpg 2x"
              />
              <Typography className="pl-3"></Typography>
            </ListItemButton>
          </ListItem> */}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddList;
