import Axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import Switch from '@mui/material/Switch';
import useAuth from '../../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';

const CreateUsers = () => {
  const [name, setfullName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [Country, setcountry] = useState<string>('');
  const [StreetAddress, setstreetAddress] = useState<string>('s');
  const [City, setcity] = useState<string>();
  const [Province, setprovince] = useState<string>('s');
  const [ZipCode, setzipcode] = useState<string>('s');
  const [state, setState] = useState({
    selectedOption: 'Student',
  });
  const [countryOption, setCountryOption] = useState<any>(null);
  const { state: authState } = useAuth();
  const { userInfo } = authState;
  const navigate = useNavigate();

  
  const radioButtonOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      selectedOption:
        prev.selectedOption === e.target.value
          ? prev.selectedOption
          : e.target.value,
    }));
  };

  const submithandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    //console.log({ Country, StreetAddress, City, Province, ZipCode })
    const { data } = await Axios.post(
      '/api/user/',
      {
        user: { name, email },
        usertype: state.selectedOption,
        Address: { Country, StreetAddress, City, Province, ZipCode },
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    navigate('../AllUsers');
    setfullName('');
    setEmail('');
    setcity('');
    setstreetAddress('');
    setcountry('');
    setprovince('');
    setzipcode('');
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const { data } = await Axios.get(`/api/user/approvedCountry`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setCountryOption(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchdata();
  }, [userInfo]);

  return (
    <>
      <div className="mt-5 grid grid-cols-3 gap-6">
        <div className=" mt-5 col-span-3 ">
          <form
            className="px-4 "
            onSubmit={submithandler}
            method="POST"
            action="#"
          >
            <div className="overflow-hidden drop-shadow-2xl sm:rounded-md">
              <div className=" bg-slate-50 px-4 py-5 ">
                <div className="font-semibold flex justify-start -ml-4 -mt-5 pl-8 pt-2 pb-2 mb-2 items-center capitalize border-l-4 border-indigo-500">
                  create Users
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 flex justify-end px-4 space-x-4 items-center mr-14">
                    <div className="flex items-center ">
                      <Switch
                        type="radio"
                        value="Student"
                        name="Student"
                        checked={state.selectedOption === 'Student'}
                        onChange={radioButtonOnChange}
                        // color="#0CC2F1"
                        // className="text-black"
                      />
                      <label htmlFor="Student">Student</label>
                    </div>
                    <div className="flex items-center">
                      <Switch
                        type="radio"
                        value="Instructor"
                        name="Instructor"
                        checked={state.selectedOption === 'Instructor'}
                        onChange={radioButtonOnChange}
                      />
                      <label htmlFor="Instructor">Instructor</label>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      value={name}
                      required
                      onChange={(e) => setfullName(e.target.value)}
                      autoComplete="given-name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Full Name"
                    />
                  </div>
                  {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="text-left block text-sm font-medium text-gray-700"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        value={name}
                        onChange={(e) => setfullName(e.target.value)}
                        autoComplete="given-name"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div> */}

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email-address"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      name="email-address"
                      id="email-address"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Email"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      value={Country}
                      required
                      // defaultValue={''}
                      onChange={(e) => setcountry(e.target.value)}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="choose ur country"
                    >
                      <option defaultChecked disabled>
                        choose ur country
                      </option>
                      {countryOption?.data.map((cou: any) => (
                        <option value={cou._id} key={cou._id}>
                          {cou.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="street-address"
                      className="text-left block text-sm font-medium text-gray-700"
                    >
                      Street address
                    </label>
                    <input
                      type="text"
                      name="street-address"
                      id="street-address"
                      value={StreetAddress}
                      required
                      onChange={(e) => setstreetAddress(e.target.value)}
                      autoComplete="street-address"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <AddressForm apikey="test_08ff553100c78f06e1d4bfbda89d3177542" />
                  </div> */}

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-left text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={City}
                      required
                      onChange={(e) => setcity(e.target.value)}
                      autoComplete="address-level2"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="region"
                      className="block text-left text-sm font-medium text-gray-700"
                    >
                      State / Province
                    </label>
                    <input
                      type="text"
                      name="region"
                      id="region"
                      value={Province}
                      required
                      onChange={(e) => setprovince(e.target.value)}
                      autoComplete="address-level1"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div> */}

                  {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="postal-code"
                      className="block text-left text-sm font-medium text-gray-700"
                    >
                      ZIP / Postal code
                    </label>
                    <input
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      value={ZipCode}
                      required
                      onChange={(e) => setzipcode(e.target.value)}
                      autoComplete="postal-code"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div> */}
                </div>
              </div>
              <div className="bg-slate-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="transition ease-in-out delay-150  duration-300 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:-translate-y-1 hover:scale-110 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  // onClick={(e) => submithandler(e)}
                >
                  <SaveIcon />
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateUsers;
