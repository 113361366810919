import React, { useEffect } from 'react';
import { IconButton, TableCell, tableCellClasses } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import useAuth from '../../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';
import AssignCourse from '../../Instructor/AssignCourse';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../ConfirmationDialog/ConfimationDialog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface Datas {
  _id: string;
  name: string | number;
  email: string | number;
  // assignedCourse: Array<string> | number;
  role: string | number;
  verified: boolean;
  active: boolean;
  Action: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Datas;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Full Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: true,
    label: 'Role',
  },
  // {
  //   id: 'assignedCourse',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Number of Course',
  // },

  { id: 'Action', numeric: false, disablePadding: true, label: 'Action' },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Datas
  ) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Datas) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: '20%', p: 2 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              // className="flex justify-center"
            >
              <span className="text-[12px] font-[600]">{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const AllUsers = () => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Datas>('name');
  // const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [usersdata, setusersdata] = React.useState<any>();
  const navigate = useNavigate();
  const [selectedUser, setselectedUser] = React.useState({});
  const [search, setSearch] = React.useState('');
  const [active, setactive] = React.useState();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { state } = useAuth();
  const { userInfo } = state;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Datas
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const assignhandler = (event: React.MouseEvent<unknown>, user: any) => {
    event.preventDefault();
    setOpen(true);
    setselectedUser(user);
  };

  const deleteHandler = async (event: React.MouseEvent<unknown>, user: any) => {
    event.preventDefault();
    setOpenConfirm(true);
    setselectedUser(user);
  };

  const deActivateHandler = async (
    event: React.MouseEvent<unknown>,
    user: any
  ) => {
    try {
      const { data } = await Axios.post(
        `/api/user/deactivate/${user._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success(data.data, {
        autoClose: 500,
        hideProgressBar: false,
        draggable: true,
        pauseOnHover: false,
      });
      setactive(user);
    } catch (err) {
      console.log(err);
      toast.error(getError(err));
    }
  };

  const activateHandler = async (
    event: React.MouseEvent<unknown>,
    user: any
  ) => {
    try {
      const { data } = await Axios.post(
        `/api/user/activate/${user._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success(data.data, {
        autoClose: 500,
        hideProgressBar: false,
        draggable: true,
        pauseOnHover: false,
      });
      setactive(user);
    } catch (err) {
      console.log(err);

      toast.error(getError(err));
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, User: any) => {
    if (User.role === 'Student') navigate(`../StudentCourses/${User._id}`);
    if (User.role === 'Instructor')
      navigate(`../InstructorCourses/${User._id}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const assignCallback = (payload: any) => {
    setOpen(payload);
  };

  const confirmCallback = (payload: any, reload: any) => {
    setOpenConfirm(payload);
    setactive(reload);
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const { data } = await Axios.get(
          `/api/user/?role=neSuperAdmin&page=${page}&limit=${rowsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        setusersdata(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchdata();
  }, [active, page, rowsPerPage, userInfo]);

  return (
    <>
      {open && (
        <AssignCourse
          user={selectedUser}
          opens={open}
          callback={assignCallback}
        />
      )}
      {openConfirm && (
        <ConfirmationDialog
          user={selectedUser}
          openDialog={openConfirm}
          callback={confirmCallback}
          messsage={`Are you sure you want to remove this user`}
          title="Delete Confirmation"
        />
      )}
      <div className="mt-5 grid grid-cols-3 gap-6">
        <div className="mt-5 col-span-3 ">
          <div className="flex justify-between gap-5">
            <div className="px-4 py-3 w-96  drop-shadow-xl">
              <form className="">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <SearchIcon fontSize="small" />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 "
                    placeholder="Search users by name"
                    onChange={(e) => setSearch(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    className="text-white absolute inset-y-0 right-0 bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:outline-none focus:ring-indigo-500 font-medium rounded-lg text-sm px-4 py-2 "
                    disabled
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Box className="px-4 py-3 w-full h-5/6">
            <Paper
              elevation={0}
              sx={{ width: '100%', mb: 2 }}
              className="drop-shadow-2xl bg-slate-50"
            >
              {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  {/* selected.length */}
                  <EnhancedTableHead
                    numSelected={0}
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={usersdata?.count}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {usersdata &&
                      stableSort(usersdata.data, getComparator(order, orderBy))
                        .slice()
                        .filter((item) => {
                          return search.toLowerCase() === ''
                            ? item
                            : item.name.toLowerCase().includes(search);
                        })
                        .map((row, index) => {
                          // const isItemSelected = isSelected(row.title as string);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              sx={{
                                height: 40,
                              }}
                              key={index}
                            >
                              <StyledTableCell
                                component="th"
                                id={labelId}
                                onClick={(event) => handleClick(event, row)}
                                scope="row"
                              >
                                <p className="text-[12px]  h-5 line-clamp-2">
                                  {row.name}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className=" text-[12px]  h-5 line-clamp-2">
                                  {row.email}
                                </p>
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className="text-[12px] h-5  line-clamp-2">
                                  {row.role}
                                </p>
                              </StyledTableCell>
                              {/* <StyledTableCell
                                onClick={(event) => handleClick(event, row)}
                                align="left"
                              >
                                <p className="text-[12px] h-5  line-clamp-2">
                                  {row.assignedCourse?.length}
                                </p>
                              </StyledTableCell> */}
                              <StyledTableCell align="left">
                                <div className="flex justify-start space-x-1">
                                  <Tooltip
                                    title={
                                      row.active ? 'Deactivate' : 'Activate'
                                    }
                                  >
                                    {row.active ? (
                                      <IconButton
                                        onClick={(event) =>
                                          deActivateHandler(event, row)
                                        }
                                      >
                                        <ToggleOnIcon
                                          fontSize="medium"
                                          className="text-indigo-500"
                                        />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={(event) =>
                                          activateHandler(event, row)
                                        }
                                      >
                                        <ToggleOffIcon
                                          fontSize="medium"
                                          className="text-red-500"
                                        />
                                      </IconButton>
                                    )}
                                  </Tooltip>
                                  {row.role === 'Instructor' ? null : (
                                    <Tooltip title="Assign Course">
                                      <IconButton
                                        onClick={(event) =>
                                          assignhandler(event, row)
                                        }
                                      >
                                        <PersonAddIcon
                                          fontSize="small"
                                          className="text-indigo-500"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Delete">
                                    <IconButton
                                      onClick={(event) =>
                                        deleteHandler(event, row)
                                      }
                                    >
                                      <DeleteIcon
                                        fontSize="small"
                                        className="text-red-500"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                    {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </div>
    </>
  );
};

export default AllUsers;
