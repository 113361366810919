import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { HashLink as Link } from 'react-router-hash-link';
import ScrollIntoView from 'react-scroll-into-view';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Axios from 'axios';
import { toast } from 'react-toastify';
import useAuth from '../../../../setup/route-manager/useAuth';
import { getError } from '../../../../setup/util';
import PasswordStrengthBar from 'react-password-strength-bar';

type Props = {};

const InstructorProfile = (props: Props) => {
  const { state, dispatch } = useAuth();
  const [getUser, setGetUser] = useState<any>();
  const { userInfo } = state;
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isError, setIsError] = useState('');
  const [{ score, feedback }, setPasswordStrength] = useState<any>({
    score: 0,
    feedback: '',
  });

  const validateConfirmHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.currentTarget.value);
    if (newPassword !== event.currentTarget.value) {
      setIsError("The new password doesn't match the current one.");
    } else {
      setIsError('');
    }
  };

  const handleChangePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (
        newPassword.toLowerCase() === confirmPassword.toLowerCase() &&
        score > 1
      ) {
        const { data } = await Axios.put(
          '/api/auth/update/password',
          { currentPassword: oldPassword, newPassword: newPassword },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        toast.success('password changed successfully');
      } else {
        toast.error('password doent match');
      }
    } catch (err) {
      console.log(err);
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Axios.get('/api/user/currentUser', {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setGetUser(data.data);
    };
    fetchData();
  });
  // console.log(getUser);
  return (
    <div className="h-[343px] mt-10 px-4 overscroll-contain">
      <div className="flex flex-row items-center justify-start">
        <div>
          <h1 className="text-xl text-left font-medium">Account profile</h1>
          {/* <h4 className="text-xs text-left font-medium">Profile</h4> */}
        </div>
      </div>
      <div className="mt-5 flex flex-row items-start justify-items-start space-x-7">
        <div className="basis-60 bg-white rounded-lg overflow-hidden drop-shadow-xl">
          <ScrollIntoView selector="#profile" smooth={true} alignToTop={true}>
            <button
              className="flex flex-row justify-between text-base py-3.5 px-6 w-full rounded-t-lg border-b border-gray-200 cursor-pointer hover:bg-gray-100 active:bg-indigo-500"
              // href="#profile"
            >
              <span>Personal Info</span>
              <span className="">
                <KeyboardArrowRightIcon fontSize="small" />
              </span>
            </button>
          </ScrollIntoView>
          <ScrollIntoView selector="#changePwd" smooth={true} alignToTop={true}>
            <button
              className="flex flex-row justify-between text-base py-3.5 px-6 w-full rounded-b-lg border-b border-gray-200 cursor-pointer hover:bg-gray-100 active:bg-indigo-500"
              // href="#changePwd"
            >
              <span>Change Passowrd </span>
              <span className="">
                <KeyboardArrowRightIcon fontSize="small" />
              </span>
            </button>
          </ScrollIntoView>
        </div>
        <div className="grow rounded-lg">
          <div className="flex flex-col space-y-5 grow h-screen snap-y snap-mandatory overscroll-contain overflow-scroll scrollbar-none z-0 drop-shadow-2xl">
            <section
              id="profile"
              // ref={}
              className=" w-full snap-start"
            >
              <div className="flex">
                <div className="basis-full bg-white h-screen rounded-lg overflow-hidden drop-shadow-xl">
                  <h1 className="text-left px-4 py-2 border-l-4 border-transparent border-indigo-600">
                    Personal Info
                  </h1>
                  <div className="my-5 flex flex-col items-center justify-items-start space-y-7">
                    <div>
                      <Avatar
                        alt="Profile Image"
                        src=""
                        className="w-40 h-40"
                      />
                    </div>
                    <div className="flex flex-row space-x-4  text-gray-700">
                      <h3 className="text-3xl text-center font-black uppercase">
                        {getUser?.name}
                      </h3>
                      <span className="w-1 h-8 rounded-lg bg-indigo-500"></span>

                      <h3 className="text-3xl text-center font-black uppercase">
                        Addis Ababa
                      </h3>
                    </div>
                    <div className="text-2xl text-center text-gray-700 font-semibold">
                      {getUser?.email}
                    </div>
                    <div className="flex flex-row">
                      <div className="text-base text-gray-500">
                        ethiopia ,Addis Abba, StreetAddress, zipcode
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="changePwd" className=" w-full snap-center">
              <div className="flex">
                <div className="basis-full bg-white h-screen rounded-lg overflow-hidden drop-shadow-xl">
                  <div className="my-3 space-x-5">
                    <h1 className="text-left px-4 py-2 border-l-4 border-transparent border-indigo-600">
                      Change password
                    </h1>
                    <form
                      action=""
                      className=""
                      onSubmit={(e) => handleChangePassword(e)}
                    >
                      <div className="mx-10 mt-5 grid grid-cols-2 gap-x-8 gap-y-0">
                        <div className="col-span-1">
                          <label
                            htmlFor="password"
                            className="text-left text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                          >
                            Old Password
                          </label>
                          <input
                            id="OldPassword"
                            name="password"
                            type="password"
                            onChange={(e) => setOldPassword(e.target.value)}
                            value={oldPassword}
                            autoComplete="current-password"
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Password"
                          />
                        </div>
                        <div className="relative col-span-1">
                          <label
                            htmlFor="password"
                            className="text-left text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                          >
                            New Password
                          </label>
                          <input
                            id="NewPassword"
                            name="password"
                            type="password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            autoComplete="current-password"
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Password"
                          />
                          {score <= 1 ? (
                            <span className="mt-2 text-red-500 text-left text-xs block">
                              {feedback}
                            </span>
                          ) : null}
                          <PasswordStrengthBar
                            className="mt-2"
                            minLength={8}
                            password={newPassword}
                            onChangeScore={(e, f) =>
                              setPasswordStrength({
                                score: e,
                                feedback: f.warning,
                              })
                            }
                          />
                        </div>
                        <div className="col-span-1">
                          <label
                            htmlFor="password"
                            className="text-left text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                          >
                            Confirm Password
                          </label>
                          <input
                            id="ConfirmPassword"
                            name="password"
                            type="password"
                            onChange={(e) => validateConfirmHandler(e)}
                            value={confirmPassword}
                            autoComplete="current-password"
                            required
                            className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 ${
                              isError
                                ? 'focus:ring-red-500 focus:border-red-500'
                                : 'focus:ring-blue-500 focus:border-blue-500'
                            }`}
                            placeholder="Password"
                          />
                          <span className="text-red-500 text-left text-sm block mt-2">
                            {isError}
                          </span>
                        </div>
                      </div>
                      <div className="mr-7 bg-gray-50 px-4 py-3 text-right">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-5 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          // onClick={nextsubmithandler}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorProfile;
