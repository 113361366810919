import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import SaveIcon from '@mui/icons-material/Save';
import React, { useState, useContext, useReducer, useEffect } from 'react';

import AddList from './AddList';
import Axios from 'axios';
import {
  Box,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import FileService from '../../../../service/FileService';
import {
  validateFileSize,
  validateFileType,
} from '../../../../service/FileValidatorService';
import { adminContext } from '../../../../setup/context-manager/admin';
import useAuth from '../../../../setup/route-manager/useAuth';
import FormSteps from '../FormSteps';
import { getError } from '../../../../setup/util';

type Action = { type: 'ADD_LESSON'; payload: any };

const initState = {
  Lessons: [] as any[],
  isloading: true,
  error: '',
};

const reducer = (state: typeof initState, action: Action) => {
  switch (action.type) {
    case 'ADD_LESSON':
      return {
        ...state,
        Lessons: [...state.Lessons, action.payload],
        isloading: false,
        error: 'success',
      };
  }
};

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const CreateLessons = () => {
  const [title, setTitle] = useState<string>('');
  const [{ description, wordCount }, setdescription] = useState({
    description: '',
    wordCount: 0,
  });
  const [files, setfiles] = useState<File[]>([]);
  const [uplaodFormError, setuploadFormError] = useState<string>('');
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initState);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [VideoUrl, setVideoUrl] = useState<string[]>([]);
  const [hidden, sethidden] = useState(false);
  const [requiredError, SetRequiredError] = useState({
    title: '',
    description: '',
    files: '',
    VideoUrl: '',
  });
  const { state: ctxState, dispatch: ctxDispatch } = useContext(adminContext);
  const { state: authstate } = useAuth();
  const { userInfo } = authstate;
  const fileTypes = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'swf',
    'mkv',
  ];
  const minWord = 100;
  const maxWord = 150;

  const uploadhandler = async (file: any) => {
    try {
      sethidden(true);
      if (!file) {
        return;
      }
      // console.log(file);
      const filesizevalidator = await validateFileSize(2e9, file.size);
      const fileTypevalidator = await validateFileType(
        fileTypes,
        FileService.getFileExtention(file.name)
      );

      if (!filesizevalidator.isvalid) {
        setuploadFormError(filesizevalidator.errorMessage);
        console.log(filesizevalidator.errorMessage);
        setfiles([]);
      }

      if (!fileTypevalidator.isvalid) {
        setuploadFormError(fileTypevalidator.errorMessage);
        console.log(fileTypevalidator.errorMessage);
        setfiles([]);
      }
      if (
        !uplaodFormError &&
        filesizevalidator.isvalid &&
        fileTypevalidator.isvalid
      ) {
        const fileservice = new FileService([file]);
        const res = await Axios.post(
          '/api/course/uploadFiles',
          fileservice.getFormData(),
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress(progressEvent) {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadPercentage(percentage);
              setTimeout(() => setUploadPercentage(0), 2000);
            },
          }
        );
        console.log(res.data.data[0]);
        setVideoUrl([...VideoUrl, res.data.data[0]]);
        setfiles([...files, file]);
      }
      setuploadFormError('');
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const DeleteUploadhandler = async (
    e: React.MouseEvent<HTMLButtonElement>,
    removedFile: File
  ) => {
    try {
      const num = files.indexOf(removedFile);
      const newfiles = files.filter((file) => file !== removedFile);
      const newurl = VideoUrl.filter((url) => url === VideoUrl[num]);
      setfiles(newfiles);
      setVideoUrl(VideoUrl.filter((url) => url !== VideoUrl[num]));
      //backend must be implemented
      const { data } = await Axios.post(
        '/api/course/deleteUpload',
        {
          newurl,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
    } catch (err: any) {
      toast.error(err);
    }
  };

  const handleprogress = () => sethidden(true);

  const savesubmithandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // console.log(ctxState.createCourse);
    try {
      const { data } = await Axios.post(
        '/api/course/',
        {
          Course: ctxState.createCourse,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success('Course Created Successfully');
      navigate('../Course');
    } catch (err: any) {
      toast.error(getError(err));
    }
  };

  const addLessonHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!title || !description || !files || !VideoUrl) {
      SetRequiredError({
        title: !title ? 'Lesson title is required field' : '',
        description: !description ? 'Lesson description is required field' : '',
        files: !files.length ? 'Lesson Video is required field' : '',
        VideoUrl: !VideoUrl.length ? 'Lesson Video is required field' : '',
      });
    } else {
      dispatch({
        type: 'ADD_LESSON',
        payload: { title, description, files, VideoUrl },
      });
      setTitle('');
      setFormattedContent('');
      setfiles([]);
      setVideoUrl([]);
    }
  };

  useEffect(() => {
    ctxDispatch({
      type: 'Add_LESSON',
      payload: state.Lessons,
    });
  }, [ctxDispatch, state.Lessons, files]);

  const setFormattedContent = React.useCallback(
    (text: any) => {
      let words = text.split(' ').filter(Boolean);
      if (words.length > maxWord) {
        setdescription({
          description: words.slice(0, maxWord).join(' '),
          wordCount: maxWord,
        });
      }
      // else if (words.length < minWord) {
      //   SetRequiredError({
      //     ...requiredError,
      //     description: !description
      //       ? `Description has to reach atleast ${minWord} words`
      //       : '',
      //   });
      // }
      else {
        setdescription({ description: text, wordCount: words.length });
      }
    },
    [minWord, maxWord, setdescription]
  );

  React.useEffect(() => {
    setFormattedContent(description);
  }, []);

  return (
    <>
      <div className="mt-5 grid grid-cols-3 gap-6">
        <div className="mt-5 col-span-3">
          <FormSteps step={1} />
        </div>
        <div className="col-span-2 ml-4">
          <form encType="multipart/form-data">
            <div className="drop-shadow-2xl sm:overflow-hidden sm:rounded-md w-full">
              <div className=" space-y-6 bg-slate-50 px-4 py-5 sm:p-6 ">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="courseTitle"
                      className="block text-sm text-left font-medium text-gray-700"
                    >
                      * Lesson Title
                    </label>
                    <input
                      type="text"
                      name="lessonTitle"
                      value={title}
                      id="lessonTitle"
                      onChange={(e) => setTitle(e.target.value)}
                      className={`${
                        requiredError.title ? 'border-red-500 ring-red-500' : ''
                      } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                    />
                    {requiredError.title ? (
                      <span className="text-red-500 flex mt-1 font-semibold ">
                        {requiredError.title}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="about"
                    className="block text-sm text-left font-medium text-gray-700"
                  >
                    * Description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      rows={3}
                      value={description}
                      onChange={(e) => setFormattedContent(e.target.value)}
                      className={`${
                        requiredError.description
                          ? 'border-red-500 ring-red-500'
                          : ''
                      } mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                      placeholder="About the Course"
                    />
                    <div className="flex absolute bottom-0 right-5 gap-2 opacity-50">
                      <span>min word {minWord}</span>
                      <span>max word {maxWord}</span>
                    </div>
                  </div>
                  {requiredError.description ? (
                    <span className="text-red-500 flex mt-1 font-semibold ">
                      {requiredError.description}
                    </span>
                  ) : null}

                  {/* <p className="mt-2 text-sm text-gray-500">
                    Brief description for your profile. URLs are hyperlinked.
                  </p> */}
                </div>
                <div>
                  <div className="flex flex-row">
                    {files.map((file, index) => {
                      return (
                        <div
                          key={index}
                          className="flex p-1 bg-gray-200 border text-sm font-bold text-left text-indigo-400 rounded-full"
                        >
                          <span className="pt-1">{file.name}</span>
                          <IconButton
                            key={index}
                            size="small"
                            onClick={(e) => DeleteUploadhandler(e, file)}
                          >
                            <CloseIcon fontSize="small" />
                            {/* <XMarkIcon className="text-gray-700 w-4 h-4" /> */}
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={`${
                      requiredError.VideoUrl ? 'border-red-500' : ''
                    } mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6`}
                  >
                    <FileUploader
                      // class="file_upload"
                      className="m-0"
                      onClick={(e: any) => handleprogress()}
                      handleChange={uploadhandler}
                      name="file-upload"
                      types={fileTypes}
                      multiple={false}
                      fileOrFiles={files}
                      children={
                        <div className="space-y-1 text-center">
                          <VideoCameraFrontIcon fontSize="small" />
                          {/* <VideoCameraIcon className="mx-auto text-gray-400 w-12 h-12" /> */}
                          <div className="text-center text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className=" relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>* Upload a Video</span>
                            </label>
                          </div>
                          {uplaodFormError && (
                            <p className="text-red-500">{uplaodFormError}</p>
                          )}
                          <p className="text-center text-xs text-gray-500">
                            MP4, MOV, WMV, AVI, AVCHD, FLV, F4V, SWF, MKV up to
                            2GB
                          </p>
                        </div>
                      }
                    />
                    {hidden && (
                      <div>
                        <LinearProgressWithLabel value={uploadPercentage} />
                      </div>
                    )}
                  </div>
                  {requiredError.VideoUrl ? (
                    <span className="text-red-500 flex mt-1 font-semibold ">
                      {requiredError.VideoUrl}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="md:col-span-1 px-4 py-3 bg-white text-right sm:px-6">
                <button
                  className="inline-flex space-y-6 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={addLessonHandler}
                >
                  <AddCircleIcon fontSize="small" />
                  {/* <PlusCircleIcon className=" w-4 h-4" /> */}
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-5 md:col-span-1 mr-4 md:mt-0">
          <div className="max-h-[28.8rem] bg-slate-50 scrollbar-thin scrollbar-thumb-indigo-100 scrollbar-track-gray-100 drop-shadow-2xl sm:rounded-md w-full">
            <div className="h-full space-y-3 px-4 py-5 ">
              <div>
                {state.isloading ? (
                  <div>Empty Lessons</div>
                ) : (
                  state.Lessons.map(
                    (
                      item: {
                        files: File[];
                        description: string | undefined;
                        title: string | undefined;
                      },
                      i: React.Key | null | undefined
                    ) => {
                      return (
                        <AddList
                          key={i}
                          files={item.files}
                          description={item.description}
                          lessonTitle={item.title}
                        />
                      );
                    }
                  )
                )}
              </div>
              <div className=" px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={savesubmithandler}
                  disabled={!state.Lessons.length}
                >
                  <SaveIcon fontSize="small" />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLessons;
