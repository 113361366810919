import React, { Children, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Axios from 'axios';
import { toast } from 'react-toastify';
import useAuth from '../../../../setup/route-manager/useAuth';
import { getError } from '../../../../setup/util';

type CustomDialogProps = {
  openDialog: boolean;
  callback: (payload: boolean) => void;
  children: React.ReactNode;
  selected: string[];
  dialogTitle: string;
  course: any;
};

const CustomAssignUserDialog = ({
  openDialog,
  callback,
  children,
  dialogTitle,
  selected,
  course,
}: CustomDialogProps) => {
  const [open, setOpen] = React.useState(openDialog);
  const { state } = useAuth();
  const { userInfo } = state;

  const handleClose = () => {
    setOpen(!open);
    callback(!open);
  };

  const submitAssignedUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const { data } = await Axios.post(
        `/api/course/assignUser/${course._id}`,
        {
          selectedUser: selected,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      setOpen(!open);
      callback(!open);
      const res = await Axios.post(
        '/api/notify/notifyAssignUser',
        {
          selectedUser: selected,
          courseId: course._id,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success(data.status);
    } catch (err) {
      console.log(err);
      toast.error(getError(err));
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="text-center font-bold text-xl uppercase text-indigo-500">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions className="flex py-4 space-x-3 mx-3">
          <button
            onClick={handleClose}
            className="font-semibold py-2 px-4 rounded-lg bg-red-500 text-white shadow-lg hover:bg-white hover:text-red-500 hover:drop-shadow-xl outline hover:outline-red-500"
          >
            Cancel
          </button>
          <button
            onClick={submitAssignedUser}
            className="font-semibold py-2 px-4 rounded-lg bg-indigo-500 text-white shadow-lg hover:bg-white hover:text-indigo-500 hover:drop-shadow-xl outline hover:outline-indigo-500"
          >
            Done
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomAssignUserDialog;
