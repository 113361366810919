import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
  return (
    <main className="App">
      <Outlet />
      <ToastContainer
        position="top-center"
        limit={1}
        // autoClose={100}
        // hideProgressBar={true}
        newestOnTop={true}
      />
    </main>
  );
};

export default Layout;
