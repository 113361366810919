import { IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import Video from './Video';
import Axios from 'axios';
import { toast } from 'react-toastify';
import useAuth from '../../../../setup/route-manager/useAuth';
import { getError } from '../../../../setup/util';
import UploadForm from './uploader';

enum flag {
  delete = 'Delete',
  edit = 'Edit',
}

type Props = {
  lesson: any;
  openEdit: boolean;
  callback: ({
    Lesson,
    flag,
  }: {
    Lesson: { id: string; title: string; description: string; Video: any[] };
    flag: flag;
  }) => void;
};

const Lesson = ({ lesson, openEdit, callback }: Props) => {
  const { state } = useAuth();
  const { userInfo } = state;
  const [lessonOpen, setLessonOpen] = useState<any>(false);
  const [lessonTitle, setLessonTitle] = useState<any>(lesson?.title);
  const [lessonDescription, setLessonDescription] = useState<any>(
    lesson?.description
  );
  const [lessonVideo, setLessonVideo] = useState<any>(lesson?.Video);
  const [enableEdit, setEnableEdit] = useState(false);
  const [openEditLesson, setOpenEditLesson] = useState<any>(false);
  const [open, setOpen] = useState<boolean | undefined>(false);

  const VideoCallback = ({
    Video,
    flag,
  }: {
    Video: { id: string; URL: string; name: string };
    flag: flag;
  }) => {
    if (flag === 'Edit') {
      //Edit video
      let editedVideo = lessonVideo.map((vid: any) => {
        if (Video.id === vid._id) {
          vid.name = Video.name;
          vid.URL = Video.URL;
        }
        return vid;
      });
      setLessonVideo(editedVideo);
    }
    if (flag === 'Delete') {
      //Delete Video
      setLessonVideo(lessonVideo.filter((vid: any) => Video.id !== vid._id));
    }
  };

  const UploaderCallback = ({
    payload,
    VideoUrl,
  }: {
    payload?: boolean;
    VideoUrl?: string;
  }) => {
    if (payload) setOpen(payload);
    // if (VideoUrl) setVideoUrl(VideoUrl);
  };
  // console.log(lessonVideo);

  const handleLessonDropDown = () => {
    setLessonOpen(!lessonOpen);
  };

  const handleEditLesson = () => {
    setOpenEditLesson(!openEditLesson);
    setLessonOpen(true);
    if (!enableEdit) {
      setLessonTitle(lesson?.title);
      setLessonDescription(lesson?.description);
      setLessonVideo(lesson?.Video);
      //   setCourseSkillToGain(course?.SkillToGain[0]);
    } else {
      callback({
        Lesson: {
          id: lesson._id,
          title: lessonTitle,
          description: lessonDescription,
          Video: lessonVideo,
        },
        flag: flag.edit,
      });
    }
    setEnableEdit(true);
  };

  const DeleteLessonHandler = async () => {
    try {
      //backend must be implemented
      const data = await Axios.delete(
        `/api/course/removeLesson/${lesson._id}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success('succesfully deleted the Lesson');
      callback({
        Lesson: {
          id: lesson._id,
          title: lesson.title,
          description: lesson.description,
          Video: lesson.Video,
        },
        flag: flag.delete,
      });
    } catch (err: any) {
      toast.error(getError(err));
    }
  };

  const uploadVideoHandler = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <button
          id="lessondetaildropdown"
          onClick={(e) => handleLessonDropDown()}
          className={`${
            openEditLesson ? 'hidden' : ''
          } my-3 font-bold w-full inline-flex outline-none items-center text-left text-gray-700 focus:outline-none `}
          type="button"
        >
          {lessonOpen ? (
            <KeyboardArrowUpIcon fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon fontSize="small" />
          )}
          <span className="pl-1">{lessonTitle}</span>
        </button>
        <input
          type="text"
          name="title"
          value={lessonTitle}
          onChange={(e) => setLessonTitle(e.target.value)}
          placeholder="Edit Lesson title"
          className={`${
            openEditLesson ? '' : 'hidden'
          } my-3 w-2/5 h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
        />
        {openEdit && (
          <div className="flex items-center space-x-2">
            <div>
              <Tooltip title="Delete Lesson">
                <IconButton
                  className="text-red-500 hover:bg-red-500 hover:text-white"
                  onClick={(event) => DeleteLessonHandler()}
                >
                  <DeleteIcon fontSize="medium" className="text-inherit" />
                </IconButton>
              </Tooltip>
            </div>
            {openEditLesson && (
              <div>
                <Tooltip title="Add Video">
                  <IconButton
                    className="text-indigo-500 hover:bg-indigo-500 hover:text-white"
                    onClick={(event) => uploadVideoHandler()}
                  >
                    <UploadIcon fontSize="medium" className="text-inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div>
              <Tooltip title="Edit Lesson">
                <IconButton
                  onClick={(e) => handleEditLesson()}
                  className="text-indigo-500  hover:text-white hover:bg-indigo-500 "
                >
                  {openEditLesson ? (
                    <DoneIcon fontSize="medium" className="text-inherit" />
                  ) : (
                    <EditIcon fontSize="medium" className="text-inherit" />
                  )}
                  {/* <span className="">{openEditTitle ? 'Done' : 'Edit'}</span> */}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      <div
        id="lessondetail"
        className={`px-10 w-full text-left ${lessonOpen ? '' : 'hidden'}`}
      >
        <div className={`${openEditLesson ? 'hidden' : ''} pl-3 text-sm  my-3`}>
          {lessonDescription}
        </div>
        <textarea
          id="about"
          name="about"
          rows={3}
          value={lessonDescription}
          placeholder="Edit Course Description"
          className={`${
            openEditLesson ? '' : 'hidden'
          } mt-3 h-20 w-3/4 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="About the Course`}
          onChange={(e) => setLessonDescription(e.target.value)}
        />
        {lessonVideo.map((video: any, index: number) => {
          return (
            <Video
              key={index}
              video={video}
              openEdit={openEditLesson}
              callback={VideoCallback}
            />
          );
        })}
      </div>
      {open ? (
        <UploadForm dialogTitle="Add Video" callback={UploaderCallback} />
      ) : null}
    </>
  );
};

export default Lesson;
