import React, { useRef, useState } from 'react';
import Container from '@mui/material/Container';
import PhoneIcon from '@mui/icons-material/Phone';
import MenuIcon from '@mui/icons-material/Menu';
type Props = {};

const navLinks = [
  {
    display: 'Home',
    url: '#hero',
  },
  {
    display: 'About',
    url: '#aboutUs',
  },
  {
    display: 'Courses',
    url: '#courses',
  },
  {
    display: 'Pages',
    url: '',
  },
  {
    display: 'Sign In',
    url: '/signin',
  },
];

const LandingHeader = (props: Props) => {
  const [toggle, setToggle] = useState<boolean>();
  const menuRef = useRef<HTMLDivElement>(null);

  const menuToggle = () => {
    const isactive = menuRef.current?.classList.toggle('active__menu', !toggle);
    setToggle(isactive);
  };

  return (
    <header className="w-full h-20 flex leading-[80px]">
      <Container>
        <div className="flex  items-center justify-between">
          <div className="flex flex-shrink-0 items-center space-x-4 xs:max-md:z-40">
            <img
              className="block h-10 w-auto lg:hidden"
              src="/samesoft.png"
              alt="Same Soft Online Learning ggggg"
            />
            <img
              className="hidden h-10 w-auto lg:block"
              src="/samesoft.png"
              alt="Same Soft Online Learning sssss"
            />
            <h2 className="">SameSoft Inc.</h2>
          </div>
          <div className="flex items-center justify-center space-x-8">
            <div
              ref={menuRef}
              onClick={menuToggle}
              className={`${
                toggle ? ':xs:max-md:block' : 'xs:max-md:hidden'
              } xs:max-md:fixed xs:max-md:top-0 xs:max-md:left-0 xs:max-md:w-full xs:max-md:h-full xs:max-md:bg-cyan-900/60 xs:max-md:z-[99]  `}
            >
              <ul className="m-0 p-0 list-none flex space-x-4 xs:max-md:absolute xs:max-md:bg-white xs:max-md:top-0 xs:max-md:right-0 xs:max-md:w-60 xs:max-md:h-full xs:max-md:flex-col xs:max-md:items-center xs:max-md:z-[999] xs:max-md:pt-20">
                {navLinks.map((item, index) => (
                  <li key={index} className="">
                    <a
                      href={item.url}
                      className="hover:text-indigo-500 font-medium text-sm no-underline transition"
                    >
                      {item.display}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="xs:max-md:hidden ">
              <p className="mb-0 flex justify-center items-center space-x-4 font-medium text-lg text-indigo-500">
                <PhoneIcon fontSize="medium" className="text-xl " />
                <span>+251 94 34 34 34</span>
              </p>
            </div>
          </div>
          <div className="xs:max-md:block hidden">
            <span>
              <MenuIcon
                fontSize="medium"
                className="text-2xl font-medium text-indigo-500"
                onClick={menuToggle}
              />
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default LandingHeader;
