import React, { useEffect } from 'react';
import Axios from 'axios';
import { Theme, useTheme } from '@mui/material/styles';
import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material';
import CustomAssignCourseDialog from './CustomDialog';
import useAuth from '../../../../setup/route-manager/useAuth';

interface AssignUserprops {
  user: any;
  opens: boolean;
  callback: (payload: boolean) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AssignCourse = ({ user, opens, callback }: AssignUserprops) => {
  const [courses, setCourses] = React.useState([] as any[]);
  const theme = useTheme();
  const [selectedCourse, setselectedCourse] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(opens);
  const handleClose = () => setOpen(open);
  const { state } = useAuth();
  const { userInfo } = state;

  const handleChange = (event: SelectChangeEvent<typeof selectedCourse>) => {
    const {
      target: { value },
    } = event;
    setselectedCourse(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const Dialogcallback = (payload: any) => {
    setOpen(payload);
    callback(payload);
  };

  useEffect(() => {
    const fetchdata = async () => {
      const { data } = await Axios.get(`/api/course/`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      console.log(data);
      setCourses(data.data.data);
    };
    fetchdata();
  }, [userInfo]);

  // console.log(courses);
  return (
    <CustomAssignCourseDialog
      dialogTitle="Assign Course"
      openDialog={open}
      selected={selectedCourse}
      callback={Dialogcallback}
      user={user}
    >
      {/* <CustomModal open={open} handleClose={handleClose}> */}
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Name</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={selectedCourse}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {courses.map((course, index) => (
              <MenuItem
                key={index}
                value={course._id}
                style={getStyles(course.title, selectedCourse, theme)}
              >
                {course.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* <div className="flex mt-2 mr-4 justify-end">
        <Button onClick={submitAssignedUser}>Assign</Button>
      </div> */}
      {/* </CustomModal> */}
    </CustomAssignCourseDialog>
  );
};

export default AssignCourse;
