class FileService {
  files: File[];

  constructor(files: File[]) {
    this.files = files;
  }

  static getFileExtention(fileName: string): string {
    const fileNames: Array<string> = fileName.split('.');

    if (fileNames.length === 0) {
      return '';
    }

    return fileNames[fileNames.length - 1];
  }

  // async uploadFile(): Promise<uploadfileResponse> {
  //   const uploadResponse = await Axios.post('', {
  //     body: this.getFormData,
  //   });
  //   return { success: true, message: '' };
  // }
  getFormData(): FormData {
    const formData = new FormData();
    this.files.map((file) => {
      formData.append('files', file);
    });
    return formData;
  }
}
export default FileService;
