import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Video from './Video';

type Props = {
  lesson: any;
};

const Lesson = ({ lesson }: Props) => {
  const [lessonOpen, setLessonOpen] = useState<any>(false);

  // console.log(lessonVideo);

  const handleLessonDropDown = () => {
    setLessonOpen(!lessonOpen);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <button
          id="lessondetaildropdown"
          onClick={(e) => handleLessonDropDown()}
          className={`my-3 font-bold w-full inline-flex outline-none items-center text-left text-gray-700 focus:outline-none `}
          type="button"
        >
          {lessonOpen ? (
            <KeyboardArrowUpIcon fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon fontSize="small" />
          )}
          <span className="pl-1">{lesson?.title}</span>
        </button>
      </div>
      <div
        id="lessondetail"
        className={`px-10 w-full text-left ${lessonOpen ? '' : 'hidden'}`}
      >
        <div className={`pl-3 text-sm  my-3`}>{lesson?.description}</div>

        {lesson?.Video.map((video: any, index: number) => {
          return <Video key={index} video={video} />;
        })}
      </div>
    </>
  );
};

export default Lesson;
