import React, { useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import Axios from 'axios';
import useAuth from '../../../../setup/route-manager/useAuth';
import TablePagination from '@mui/material/TablePagination';

type Props = {
  callback: ({ selected }: { selected: {} }) => void;
};

const Search = ({ callback }: Props) => {
  const { state } = useAuth();
  const { userInfo } = state;
  const [selected, setSelected] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChange = (data: any) => {
    setSelected(data);
    callback({ selected: data });
  };

  const handleSearch = (data: any) => {
    setSearch(data);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const { data } = await Axios.get(
          `/api/user/${
            search ? 'search' : ''
          }?role=Instructor&page=${page}&limit=${rowsPerPage}${
            search ? `&search=${search}` : ''
          }`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );

        data.data.count === 0
          ? setOptions([
              { value: null, label: 'No Instructors Found', isdisabled: true },
            ])
          : setOptions((options: any) =>
              data.data.data.map((instructor: any) => {
                return { value: instructor._id, label: instructor.name };
              })
            );
        setOptions((options: any) => [
          ...options,
          {
            value: null,
            label: (
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={-1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // className="w-full"
              />
            ),
            isdisabled: true,
          },
        ]);
      } catch (err) {}
    };
    fetchdata();
  }, [page, rowsPerPage, search, userInfo.token]);

  return (
    <Select
      autoFocus
      options={options}
      placeholder="Select Instructor"
      value={selected}
      onChange={handleChange}
      isSearchable={true}
      inputValue={search}
      // className="text-left w-full"
      onInputChange={handleSearch}
      isOptionDisabled={(option) => option.isdisabled}
      classNamePrefix="select"
      className="w-full focus:ring-0 focus:ring-transparent	foucs:ring-offset-0 focus:ring-offset-inherit"
      // styles={ {cx('select__input')=>} }
      // styles={{
      //   input: (base, state) => {
      //     return state.cx(.));
      //   },
      // }}
    />
  );
};

export default Search;
