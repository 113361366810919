import React, { useEffect } from 'react';
import Axios from 'axios';
import { Theme, useTheme } from '@mui/material/styles';
import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material';

import CustomAssignUserDialog from './CustomDialog';
import useAuth from '../../../../setup/route-manager/useAuth';

interface AssignUserprops {
  course: any;
  opens: boolean;
  callback: (payload: boolean) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AssignUser = ({ course, opens, callback }: AssignUserprops) => {
  // const [checked, setChecked] = React.useState<Number[]>([]);
  const [userdata, setuserdata] = React.useState([] as any[]);
  // const [checkedUser, setCheckedUser] = React.useState([] as any[]);
  const theme = useTheme();
  const [selectedUser, setselectedUser] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { state } = useAuth();
  const { userInfo } = state;

  const handleChange = (
    event: SelectChangeEvent<typeof selectedUser>
    // user: any
  ) => {
    const {
      target: { value },
    } = event;

    setselectedUser(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  // const submitAssignedUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
  //   await Axios.post(
  //     `/api/course/assign/${course._id}`,
  //     {
  //       selectedUser,
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     }
  //   );
  //   window.location.reload();
  //   // navigate('../Course');
  //   // setOpen(false);
  // };

  const Dialogcallback = (payload: any) => {
    setOpen(payload);
    callback(payload);
  };

  useEffect(() => {
    const fetchdata = async () => {
      const { data } = await Axios.get('/api/user/?role=Student', {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setuserdata(data.data.data);
    };
    fetchdata();
  }, [setuserdata, userInfo.token]);

  return (
    <CustomAssignUserDialog
      dialogTitle="Assign User"
      openDialog={open}
      selected={selectedUser}
      callback={Dialogcallback}
      course={course}
    >
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Name</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={selectedUser}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {userdata.map((user) => (
              <MenuItem
                key={user._id}
                value={user._id}
                style={getStyles(user.name, selectedUser, theme)}
              >
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* <div className="flex mt-2 mr-4 justify-end">
        <Button onClick={submitAssignedUser}>Assign</Button>
      </div> */}
    </CustomAssignUserDialog>
  );
};

export default AssignUser;
