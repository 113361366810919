import React from 'react';
import Container from '@mui/material/Container';
import Slider from 'react-slick';

type Props = {};

const Testimonial = (props: Props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
  };

  return (
    <section>
      <Container>
        <div className="grid grid-rows-1">
          <div className="grid lg:grid-cols-10 ">
            <div className="col-span-10 flex justify-center gap-10">
              <div className="w-2/5">
                <img src="/closeup.jpg" alt="" className="w-full rounded-lg" />
              </div>
              <div className="w-2/5 flex flex-col">
                <h1 className="px-5 text-start font-bold mb-4 uppercase">
                  Our Students Voice
                </h1>
                <Slider {...settings} className="p-5">
                  <div>
                    <h3 className="text-left font-semibold mb-3">
                      Excellent course of materials
                    </h3>
                    <p className="text-left leading-8">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Nobis repudiandae numquam voluptate itaque aspernatur
                      pariatur, reiciendis sit recusandae minima eos sint
                      laborum!
                    </p>
                    <div className="flex flex-col items-start mt-4">
                      <h4 className="font-semibold">Jhon Doe</h4>
                      <p>Addis Ababa, Ethiopia</p>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-left font-semibold mb-3">
                      Excellent course of materials
                    </h3>
                    <p className="text-left leading-8">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Nobis repudiandae numquam voluptate itaque aspernatur
                      pariatur, reiciendis sit recusandae minima eos sint
                      laborum!
                    </p>
                    <div className="flex flex-col items-start mt-4">
                      <h4 className="font-semibold">Jhon Doe</h4>
                      <p>Addis Ababa, Ethiopia</p>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-left font-semibold mb-3">
                      Excellent course of materials
                    </h3>
                    <p className="text-left leading-8">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Nobis repudiandae numquam voluptate itaque aspernatur
                      pariatur, reiciendis sit recusandae minima eos sint
                      laborum!
                    </p>
                    <div className="flex flex-col items-start mt-4">
                      <h4 className="font-semibold">Jhon Doe</h4>
                      <p>Addis Ababa, Ethiopia</p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Testimonial;
