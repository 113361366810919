/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import Lesson from './Lesson';
import { toast } from 'react-toastify';
import useAuth from '../../../../setup/route-manager/useAuth';
import { getError } from '../../../../setup/util';

const SuperAdmnDetailCourse = () => {
  const { state } = useAuth();
  const { userInfo } = state;
  const [isOpen, setOpen] = useState(true);
  const [isobjectiveOpen, setobjectiveOpen] = useState(true);
  const [course, setCourse] = useState<any>(null);
  const { id } = useParams();

  const handleCourseDropDown = () => {
    setOpen(!isOpen);
  };

  const handleObjectiveDropDown = () => {
    setobjectiveOpen(!isobjectiveOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(`/api/course/${id}`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setCourse(data.data);
        // console.log(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchData();
  }, [id, userInfo.token]);

  return (
    <>
      <div className="mt-5 grid grid-cols-3 gap-6">
        <div className="mt-5 col-span-3">
          <div className="px-4">
            <div className="drop-shadow-2xl sm:overflow-hidden sm:rounded-lg w-full">
              <div className=" bg-slate-50 px-4 py-5 sm:p-6 ">
                <div className="flex justify-between items-center">
                  <button
                    id="coursedetaildropdown"
                    onClick={handleCourseDropDown}
                    className={` w-full uppercase font-bold inline-flex outline-none justify-between items-center text-lg text-left text-gray-700 focus:outline-none `}
                    type="button"
                  >
                    {course?.title}
                    {isOpen ? (
                      <KeyboardArrowUpIcon
                        fontSize="medium"
                        className="text-indigo-500"
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        fontSize="medium"
                        className="text-indigo-500"
                      />
                    )}
                  </button>
                </div>
                <div
                  className={`pl-5 w-full text-gray-700 text-left  ${
                    isOpen ? '' : 'hidden'
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div className={`pl-3 text-sm  my-3`}>
                      {course?.description}
                    </div>
                  </div>
                  <button
                    id="lessondetaildropdown"
                    onClick={handleObjectiveDropDown}
                    className="my-3 font-bold w-full inline-flex outline-none items-center text-left text-gray-700 focus:outline-none "
                    type="button"
                  >
                    {isobjectiveOpen ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                    <span className="pl-1">Learning Objective</span>
                  </button>
                  <div
                    className={`flex flex-row justify-evenly mt-1 ${
                      isobjectiveOpen ? '' : 'hidden'
                    }`}
                  >
                    <div className="w-2/5">
                      <div className="flex justify-center items-center">
                        <span className="flex font-bold text-center">
                          What you will learn
                        </span>
                      </div>
                      <div className="mt-2">
                        <span>
                          {course?.WhatToLearn.split('\n').map(
                            (item: any, index: any) => {
                              return (
                                <span key={index} className="w-full block">
                                  {item}
                                </span>
                              );
                            }
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="w-2/5">
                      <div className="flex justify-center items-center">
                        <span className="flex font-bold text-center">
                          Skill TO Gain
                        </span>
                      </div>
                      <div className="mt-2">
                        <span>
                          {course?.SkillToGain.split('\n').map(
                            (item: any, index: any) => {
                              return (
                                <span key={index} className="w-full block">
                                  {item}
                                </span>
                              );
                            }
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divide-y-2 divide-gray-900 divide-solid"></div>
                <div className="pl-5 w-full text-gray-700 text-left">
                  {course?.Lessons.map((lesson: any, index: number) => {
                    return <Lesson key={index} lesson={lesson} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdmnDetailCourse;
