import React from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

type ReviewProps = { review: any };

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const InstructorReview = ({ review }: ReviewProps) => {
  return (
    <div
      key={review._id}
      className="my-5 bg-slate-50 flex flex-col justify-start p-5 rounded-lg divide-y drop-shadow-lg"
    >
      <div className="pb-3 flex flex-col gap-2">
        <h1 className="text-left font-semibold capitalize">
          {review.course?.title}
        </h1>
        <div className="flex self-start gap-5">
          <span className="font-medium capitalize text-base">Rating</span>
          <StyledRating
            name="highlight-selected-only"
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons[value].label}
            highlightSelectedOnly
            value={review.rating}
            // onChange={(e, value) => setRating(value)}
          />
        </div>
      </div>
      <div className="py-3 flex flex-col">
        <span className="text-left font-semibold ">FeedBack</span>
        <span className="w-4/5 text-left font-light">{review.comment}</span>
      </div>
      <div className="flex flex-row items-center gap-10 pt-3">
        <span>Reviewer</span>
        <div className="flex gap-3 items-center">
          <img
            className="h-7 w-7 rounded-full shadow-xl"
            src="/profile.jpg"
            alt=""
          />
          <span className="font-semibold text-lg capitalize">
            {review.user?.name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InstructorReview;
