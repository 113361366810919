import Container from '@mui/material/Container';
import React from 'react';
import DraftsIcon from '@mui/icons-material/Drafts';
import SourceIcon from '@mui/icons-material/Source';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

type Props = {};

const FeatureSection = (props: Props) => {
  return (
    <section>
      <Container>
        <div className="grid grid-rows-1">
          <div className="my-14 grid lg:grid-cols-3 gap-5">
            <div className="col-span-1">
              <DraftsIcon
                fontSize="large"
                className="text-indigo-500 w-14 h-14"
              />
              <h2>Quick Learning</h2>
              <p className="p-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
                beatae cum rerum soluta accusamus doloribus velit suscipit
                tempora? Dolore quia similique beatae. Itaque accusamus sit non
                sint, magnam nemo alias?
              </p>
            </div>
            <div className="col-span-1">
              <SourceIcon
                fontSize="large"
                className="text-indigo-500 w-14 h-14"
              />
              <h2>All Time Support</h2>
              <p className="p-5">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Excepturi totam necessitatibus quae laborum voluptatum
                repudiandae numquam quisquam! Repellat magni cum doloribus quas,
                maiores, repudiandae libero dignissimos nam cumque inventore
                veniam!
              </p>
            </div>
            <div className="col-span-1">
              <TextSnippetIcon
                fontSize="large"
                className="text-indigo-500 w-14 h-14"
              />
              <h2>Certification</h2>
              <p className="p-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Accusantium illum libero, magnam minus voluptatum eius
                cupiditate, eligendi adipisci facere repellat laudantium et
                veritatis maxime vitae maiores distinctio aliquid reiciendis ut.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FeatureSection;
