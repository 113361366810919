import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import useAuth from '../../../../setup/route-manager/useAuth';
import { toast } from 'react-toastify';
import { getError } from '../../../../setup/util';
import InstructorReview from './InstructorReview';

type Props = {};

const CourseReview = (props: Props) => {
  const [reviews, setReviews] = useState<any>();
  const { state: authState } = useAuth();
  const { userInfo } = authState;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(`/api/review/ownedCourseReview`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setReviews(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchData();
  }, [userInfo.token]);

  // console.log(state);

  return (
    <div className="mt-10">
      <div></div>
      <h1 className="px-4 mt-2 text-left font-semibold text-lg capitalize">
        Reviewed Course
      </h1>
      <div className="mt-5">
        {reviews?.map((review: any) => {
          return <InstructorReview key={review._id} review={review} />;
        })}
      </div>
    </div>
  );
};

export default CourseReview;
