import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Axios from 'axios';
import { toast } from 'react-toastify';
import useAuth from '../../../../setup/route-manager/useAuth';
import { getError } from '../../../../setup/util';
import DangerousIcon from '@mui/icons-material/Dangerous';

type ConfirmationDialogProps = {
  openDialog: boolean;
  title: string;
  callback: (payload: boolean, reload: any) => void;
  messsage: string;
  user?: any;
  course?: any;
};

const ConfirmationDialog = ({
  openDialog,
  callback,
  title,
  messsage,
  user,
  course,
}: ConfirmationDialogProps) => {
  const [open, setOpen] = React.useState(openDialog);
  const { state } = useAuth();
  const { userInfo } = state;

  const handleAgree = async () => {
    try {
      if (user) {
        const { data } = await Axios.delete(`/api/user/${user._id}`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        toast.success('User Deleted Successfully');
        setOpen(false);
        callback(false, user);
      }
      if (course) {
        const { data } = await Axios.delete(`/api/course/${course._id}`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        toast.success('Course Deleted Successfully');
        setOpen(false);
        callback(false, course);
      }
    } catch (err) {
      // console.log(err);
      toast.error(getError(err));
    }
  };

  const handleClose = () => {
    setOpen(false);
    callback(false, user);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="text-red-500">{title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="">
            <span
              className="flex my-5 p-4 mb-4 justify-center items-center text-lg text-red-500 rounded-lg "
              role="alert"
            >
              <DangerousIcon fontSize="small" />
              <span className="sr-only">Danger</span>
              <span className="">
                <span className="font-semibold">Danger alert! </span>
                <span>{messsage}</span>
              </span>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="font-semibold py-2 px-4 rounded-lg bg-indigo-500 text-white shadow-lg hover:bg-white hover:text-indigo-500 hover:drop-shadow-xl outline hover:outline-indigo-500"
          >
            Disagree
          </Button>
          <Button
            onClick={handleAgree}
            autoFocus
            className="font-semibold py-2 px-4 rounded-lg bg-red-500 text-white shadow-lg hover:bg-white hover:text-red-500 hover:drop-shadow-xl outline hover:outline-red-500"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
