import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CourseComp from './course';
import InfoIcon from '@mui/icons-material/Info';
import useAuth from '../../../../setup/route-manager/useAuth';

type Props = {};

const AssignedCourse = (props: Props) => {
  const { id } = useParams();
  const { state } = useAuth();
  const { userInfo } = state;
  const [courses, setCourses] = useState<any[]>([]);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Axios.get(`/api/user/${id}`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setUser(data.data);
      setCourses(data.data?.assignedCourse);
    };
    fetchData();
  });
  return (
    <div className="my-10">
      <div className="text-left pl-10 font-bold tracking-widest text-xl">
        Assigned Courses of {user?.name}
      </div>
      {!courses.length ? (
        <div
          className="flex my-5 p-4 mb-4 justify-center items-center text-lg text-blue-700 rounded-lg "
          role="alert"
        >
          <InfoIcon fontSize="small" />
          <span className="sr-only">Info</span>
          <div className="">
            <span className="font-semibold">Info alert!</span>
            <span className="text-red-500"> No Assigned Courses.</span>
          </div>
        </div>
      ) : (
        // <div className="my-10">No Assigned Courses</div>
        courses.map((course: any, index: React.Key | null | undefined) => {
          return <CourseComp key={index} Course={course} />;
        })
      )}
    </div>
  );
};

export default AssignedCourse;
