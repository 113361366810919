import React, { useEffect, useState } from 'react';

import Axios from 'axios';

import { useNavigate } from 'react-router-dom';
import useAuth from '../../../setup/route-manager/useAuth';
import HeaderScreen from '../header';
import FooterScreen from '../footer';
import { Stack, Pagination } from '@mui/material';
import { toast } from 'react-toastify';
import { getError } from '../../../setup/util';

const MyLearningScreen = () => {
  const { state, dispatch } = useAuth();
  const { userInfo } = state;
  const navigate = useNavigate();
  const [courses, setCourse] = useState([] as any[]);

  const coursehandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    course: any
  ) => {
    e.preventDefault();
    // console.log(course);
    dispatch({ type: 'COURSE', payload: course });
    navigate(`nowlearning/${course._id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(`/api/user/${userInfo.id}`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        // console.log(data.data.data[0]);
        setCourse(data.data.assignedCourse);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchData();
    // console.log(state);
  }, [userInfo.id, userInfo.token]);

  // console.log(courses);
  return (
    <div className="h-full">
      <HeaderScreen />
      <div className="bg-white h-full my-5  ">
        <div className="flex flex-col mx-auto py-5 px-5 max-w-7xl max-h-7xl">
          <h2 className="grow ml-10 text-2xl text-left font-bold tracking-tight text-gray-900">
            My Learning
          </h2>

          <div className="mt-5 mx-5 flex flex-row justify-center gap-x-6 overflow-x-scroll overscroll-x-auto scroll-smooth scrollbar-none overscroll-none scroll-mx-1">
            {courses.map((course) => (
              <div
                key={course._id}
                className="mb-14 mx-4 h-64 w-1/4 relative bg-white drop-shadow-2xl rounded-2xl"
              >
                <div className="aspect-w-1 h-48 w-full overflow-hidden group-hover:opacity-75 ">
                  <img
                    alt={course.title}
                    className=" rounded-t-3xl"
                    src={`/${course.ImageUrl.replace('public', 'localFile')}`}
                    // srcSet="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg 1x, https://img-c.udemycdn.com/course/480x270/1513876_9dfb_2.jpg 2x"
                  />
                </div>

                <div className="mt-4 flex justify-center bg-white ">
                  <h3 className=" text-lg font-bold text-indigo-500">
                    <button
                      // to="/coursedetail"
                      onClick={(e) => coursehandler(e, course)}
                    >
                      <span aria-hidden="true" className="absolute inset-0 " />
                      {course.title}
                    </button>
                  </h3>
                  {/* <p className="mt-1 text-sm text-gray-500">
                      {course.description}
                    </p> */}

                  {/* <p className="text-sm font-medium text-gray-900">
                    {course.tuition}
                  </p> */}
                </div>
              </div>
            ))}
          </div>
          <Stack spacing={2} className="m-auto my-3">
            {/* <Pagination count={courses.length / 3} /> */}
          </Stack>
        </div>
      </div>
      <FooterScreen />
    </div>
  );
};

export default MyLearningScreen;
