import React from 'react';
import Container from '@mui/material/Container';
import SearchIcon from '@mui/icons-material/Search';

type Props = {};

const HeroSection = (props: Props) => {
  return (
    <section id="hero" className="h-full">
      <Container>
        <div className="grid grid-rows-1">
          <div className="grid md:grid-cols-2 lg:grid-cols-2 space-x-5">
            <div className="col-span-1 flex flex-col items-start pt-20">
              <h1 className="text-left mb-4 font-semibold leading-10">
                AnyTime AnyWhere <br />
                Learn on your <br />
                suitable schedule
              </h1>
              <p className="mb-4 text-left leading-10">
                Lorem ipsum dolor sit amet, consectetur <br />
                adipiscing elit. Vestibulum in fringilla quam. <br />
                Fusce ultricies nibh quis metus sit amet vehicula erat luctus.
              </p>
              <div className="mt-5 w-3/4 rounded-full border-[1px] border-indigo-500 flex justify-between items-center space-x-1">
                <input
                  type="text"
                  placeholder="Search"
                  className="grow rounded-full border-0 outline-0 pl-4 p-2 focus:ring-0 focus:ring-transparent	foucs:ring-offset-0 focus:ring-offset-inherit"
                />
                <button className="h-full w-10 rounded-full bg-indigo-600 flex items-center justify-center text-white hover:bg-indigo-800 font-medium text-sm text-center ">
                  <SearchIcon fontSize="medium" />
                </button>
              </div>
            </div>
            <div className="col-span-1 drop-shadow-2xl">
              <img src="/hero.jpg" alt="" className="w-100 h-full rounded-xl" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HeroSection;
