import Container from '@mui/material/Container';
import React, { useState } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

type Props = {};

const ChooseUs = (props: Props) => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <section>
      <Container>
        <div className="grid grid-rows-1">
          <div className="my-14 grid lg:grid-cols-2 space-x-5">
            <div className="col-span-1 p-5">
              <h1 className="text-left mb-4 font-semibold leading-10">
                Why Choose Us
              </h1>
              <p className="mb-4 text-left leading-8">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
                possimus, nihil doloribus officia aliquam soluta, cupiditate
                iste, ratione animi laudantium ipsa sunt perspiciatis quaerat
                quod dicta! Minus nostrum hic quod.
              </p>
            </div>
            <div className="col-span-1  drop-shadow-2xl">
              <div className="flex  justify-center relative w-full h-full">
                {showVideo ? (
                  <video
                    className="rounded-xl drop-shadow-xl"
                    src={`/qqq.mp4`}
                    controls
                    onPause={() => setShowVideo(!showVideo)}
                    onEnded={() => setShowVideo(!showVideo)}
                  />
                ) : (
                  <>
                    <img
                      src="/chooseUs.jpg"
                      alt=""
                      className="w-full h-full rounded-lg"
                    />
                    <PlayCircleIcon
                      fontSize="large"
                      className="bg-white text-indigo-500 outline-8	 outline-offset-8 outline-indigo-500 rounded-full absolute self-center justify-self-center"
                      onClick={() => setShowVideo(!showVideo)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ChooseUs;
