import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <section>
      <div
        className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
        role="alert"
      >
        <InfoIcon
          fontSize="small"
          className="flex-shrink-0 inline w-5 h-5 mr-3"
        />
        <span className="sr-only">Info</span>
        <div>
          <span className="font-medium">Danger alert!</span>
        </div>
      </div>
      <h1>Unauthorized</h1>
      <br />
      <p>You do not have access to the requested page.</p>
      <div className="flexGrow">
        <button onClick={goBack}>Go Back</button>
      </div>
    </section>
  );
};

export default Unauthorized;
