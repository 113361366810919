import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  Typography,
  CircularProgress,
  Box,
  Tabs,
  Tab,
  Breadcrumbs,
  Stack,
  Link,
} from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import useAuth from '../../../setup/route-manager/useAuth';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import HeaderScreen from '../header';
import ReviewForm from '../ReviewForm';
import { toast } from 'react-toastify';
import FooterScreen from '../footer/index';
import { getError } from '../../../setup/util';
import { useBeforeunload } from 'react-beforeunload';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} className="text-left ">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

type Action =
  | { type: 'Add_LESSON'; payload: any }
  | { type: 'ADD_VIDEO'; payload: any }
  | { type: 'ADD_VIDEOS'; payload: any };

const initState = {
  Lesson: {},
  Video: {},
  Videos: [],
  // isloading: true,
  // error: '',
};

const reducer = (state: typeof initState, action: Action) => {
  switch (action.type) {
    case 'Add_LESSON':
      return { ...state, Lesson: action.payload };
    case 'ADD_VIDEO':
      return { ...state, Video: action.payload };
    case 'ADD_VIDEOS':
      return { ...state, Videos: action.payload };
  }
};

const NowLearning = () => {
  let location = useLocation();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [state, dispatch] = useReducer(reducer, initState);
  let { id } = useParams();
  const player = useRef<HTMLVideoElement>(null);
  const [play, setplay] = useState<any>({ id: '', url: '' });
  const [value, setValue] = React.useState(0);
  const [completed, setCompleted] = useState<any>();
  const [course, setCourse] = useState<any>(null);
  const [currentPlayState, setCurrentPlayState] = useState<any>();
  const [currentTime, setCurrentTime] = useState<number>(
    currentPlayState?.currentSeek ? currentPlayState?.currentSeek : 0
  );
  const { state: ctxstate } = useAuth();
  const { userInfo } = ctxstate;
  const navigate = useNavigate();

  const breadcrumbs = [
    <p key="1" className="text-indigo-500">
      {course ? course.title : ''}
    </p>,
    <p key="2" className="text-indigo-500">
      {state.Lesson?.title}
    </p>,
    <p key="3" className="text-indigo-500">
      {state.Video?.name}
    </p>,
  ];

  const handleAccordion =
    (panel: string, Lesson: any) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleplayer = (Vidfile: any) => {
    // console.log(state.Lesson.Video.some((vid: any) => vid._id === Vidfile._id));
    if (state.Lesson.Video.some((vid: any) => vid._id === Vidfile._id)) {
      dispatch({ type: 'ADD_VIDEO', payload: Vidfile });
      const VideoUrl = Vidfile.URL.replace('public', 'localFile');
      setplay({ id: Vidfile._id, url: VideoUrl });
    } else {
      dispatch({
        type: 'Add_LESSON',
        payload:
          course.Lessons[
            course.Lessons.findIndex((lesson: any) =>
              lesson.Video.some((vid: any) => vid._id === Vidfile._id)
            )
          ],
      });
      dispatch({ type: 'ADD_VIDEO', payload: Vidfile });
      const VideoUrl = Vidfile.URL.replace('public', 'localFile');
      setplay({ id: Vidfile._id, url: VideoUrl });
    }
  };

  // console.log(play, state);

  const handleNext = () => {
    if (state.Videos.some((vid: any) => vid._id === state.Video._id)) {
      dispatch({
        type: 'ADD_VIDEO',
        payload:
          state.Videos[
            state.Videos.findIndex((vid: any) => vid._id === state.Video._id) <
            state.Videos.length - 1
              ? state.Videos.findIndex(
                  (vid: any) => vid._id === state.Video._id
                ) + 1
              : state.Videos.findIndex(
                  (vid: any) => vid._id === state.Video._id
                )
          ],
      });
      let videourl = state.Videos[
        state.Videos.findIndex((vid: any) => vid._id === state.Video._id) <
        state.Videos.length - 1
          ? state.Videos.findIndex((vid: any) => vid._id === state.Video._id) +
            1
          : state.Videos.findIndex((vid: any) => vid._id === state.Video._id)
      ].URL?.replace('public', 'localFile');
      setplay({
        id: state.Videos[
          state.Videos.findIndex((vid: any) => vid._id === state.Video._id) <
          state.Videos.length - 1
            ? state.Videos.findIndex(
                (vid: any) => vid._id === state.Video._id
              ) + 1
            : state.Videos.findIndex((vid: any) => vid._id === state.Video._id)
        ]._id,
        url: videourl,
      });
    }
  };

  const handleOnload = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    // console.log(play.id === currentPlayState?.currentVideoWatching?._id);
    if (play.id === currentPlayState?.currentVideoWatching?._id) {
      e.currentTarget.currentTime = currentPlayState.currentSeek;
    } else {
    }
  };

  const markCompleted = async () => {
    const video = state.Lesson.Video.filter(
      (vid: any) => vid.URL === play.url.replace('localFile', 'public')
    );
    try {
      const { data } = await Axios.post(
        `/api/course/markCompleted`,
        {
          courseid: course._id,
          lessonid: state.Lesson._id,
          videoid: video[0]._id,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      setCompleted(data.data);
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const markInComplete = async () => {
    const video = state.Lesson.Video.filter(
      (vid: any) => vid.URL === play.url.replace('localFile', 'public')
    );
    try {
      const { data } = await Axios.post(
        `/api/course/markInComplete`,
        {
          courseid: course._id,
          lessonid: state.Lesson._id,
          videourl: video[0]._id,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      // console.log('mark incomplete  ', data);
      setCompleted(data.data);
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    //fetch course data
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(`/api/course/${id}`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setCourse(data.data);
        //first  loaded lesson
        dispatch({ type: 'Add_LESSON', payload: data.data.Lessons[0] });
        dispatch({
          type: 'ADD_VIDEO',
          payload: data.data.Lessons[0].Video[0],
        });
        let videourl = data.data.Lessons[0].Video[0].URL?.replace(
          'public',
          'localFile'
        );
        setplay({ id: data.data.Lessons[0].Video[0]._id, url: videourl });

        const arrVideo: any[] = [];
        data.data.Lessons.forEach((lesson: any) => {
          lesson.Video.forEach((vid: any) => {
            arrVideo.push(vid);
          });
        });
        dispatch({
          type: 'ADD_VIDEOS',
          payload: arrVideo,
        });
      } catch (err: any) {
        if (err.response.status === 401) {
          navigate('/unauthorized', { state: { from: '/mylearning' } });
          toast.error(getError(err));
        } else {
          toast.error(getError(err));
        }
      }
    };
    fetchData();
  }, [id, navigate, userInfo]);

  useEffect(() => {
    //load progress for course
    const loadCompletedLessons = async () => {
      try {
        const { data } = await Axios.post(
          '/api/course/ListCompleted',
          {
            courseid: id,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        setCompleted(data.data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    loadCompletedLessons();
  }, [id, userInfo.token]);

  useEffect(() => {
    //change lesson
    if (
      state.Lesson?.Video?.filter((vid: any) => vid._id === state.Video?._id)
        .length === 0
    ) {
      setExpanded(
        expanded === false
          ? `panel${course?.Lessons.findIndex((lesson: any) =>
              lesson.Video.some((vid: any) => vid._id === state.Video?._id)
            )}`
          : expanded
      );
    }
  }, [state]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await Axios.get(`/api/course/videotime/${id}`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        setCurrentPlayState(data.data);
        dispatch({
          type: 'Add_LESSON',
          payload: data.data.currentLesson,
        });
        dispatch({
          type: 'ADD_VIDEO',
          payload: data.data.currentVideoWatching,
        });
        let videourl = data.data.currentVideoWatching.URL?.replace(
          'public',
          'localFile'
        );
        setplay({ id: data.data.currentVideoWatching._id, url: videourl });
      } catch (err) {}
    })();
  }, [id, userInfo.token]);

  useBeforeunload((event) =>
    // 'You’ll lose your data!'
    {
      if (event) {
        (async () => {
          const { data } = await Axios.post(
            `/api/course/videotime/${id}`,
            { time: currentTime, video: state.Video, lesson: state.Lesson },
            {
              headers: {
                Authorization: `Bearer ${userInfo.token}`,
              },
            }
          );
        })();
      }
      // console.log(state.Video);
      // console.log(state.Lesson);
      // console.log(currentTime);
      event.preventDefault();
    }
  );

  return (
    <>
      <HeaderScreen />
      <div className="my-5 mx-10 h-full">
        <div className="my-5 grid grid-rows-1 grid-cols-3 gap-2">
          <div className="p-5 col-span-2 row-span-1 mt-0 ">
            <div className="rounded-3xl bg-white drop-shadow-2xl ">
              <div
                className="rounded-t-3xl flex justify-between bg-blue-100 border-t border-b border-blue-500 text-indigo-500 px-4 py-3"
                role="alert"
              >
                <Stack spacing={2}>
                  <Breadcrumbs
                    className="text-indigo-500"
                    separator={
                      <KeyboardArrowRightIcon
                        fontSize="small"
                        className="text-indigo-500"
                      />
                      // <ChevronRightIcon className=" text-indigo-500 w-3 h-3" />
                    }
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs}
                  </Breadcrumbs>
                </Stack>
                {completed ? (
                  completed?.video.includes(state.Video?._id) ? (
                    <span
                      className="flex justify-end cursor-pointer"
                      onClick={markInComplete}
                    >
                      Mark as incomplete
                    </span>
                  ) : (
                    <span
                      className="flex justify-end cursor-pointer"
                      onClick={markCompleted}
                    >
                      Mark as completed
                    </span>
                  )
                ) : (
                  <span
                    className="flex justify-end cursor-pointer"
                    onClick={markCompleted}
                  >
                    Mark as completed
                  </span>
                )}
              </div>
              {play.url === '' ? (
                <div className="my-28">
                  <CircularProgress />
                </div>
              ) : (
                <video
                  className="rounded-b-3xl bg-white drop-shadow-xl w-full h-full"
                  ref={player}
                  id="videoPlay"
                  src={`/${play.url}`}
                  controls
                  autoPlay={true}
                  onEnded={() => {
                    markCompleted();
                    handleNext();
                  }}
                  onLoadedData={(e) => handleOnload(e)}
                  onPause={(e) => setCurrentTime(e.currentTarget.currentTime)}
                />
              )}
            </div>
          </div>
          <div className="p-5 col-span-1 row-span-1">
            <div className="max-h-[28.8rem] bg-white  drop-shadow-2xl scrollbar-thin scrollbar-thumb-indigo-100 scrollbar-track-gray-100 shadow sm:rounded-md w-full">
              <div className="h-full space-y-3 px-4 py-5 ">
                <div>
                  {course ? (
                    course.Lessons.map(
                      (item: any, i: React.Key | null | undefined) => {
                        return (
                          <Accordion
                            key={i}
                            className="pb-2"
                            expanded={expanded === `panel${i}`}
                            onChange={handleAccordion(`panel${i}`, item)}
                          >
                            <AccordionSummary
                              expandIcon={
                                <KeyboardArrowDownIcon
                                  fontSize="small"
                                  className="text-indigo-500"
                                />
                                // <ChevronDownIcon className="w-3 h-3" />
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography
                                className="flex justify-between text-left"
                                variant="subtitle1"
                                sx={{ width: '100%', flexShrink: 0 }}
                              >
                                Title: {item.title}{' '}
                                {completed ? (
                                  completed.lesson.includes(item._id) ? (
                                    // <CheckCircleIcon className="mt-1.5 justify-items-end text-indigo-500 w-4 h-4" />
                                    <CheckCircleIcon
                                      fontSize="small"
                                      className="text-indigo-500"
                                    />
                                  ) : (
                                    <RemoveCircleIcon
                                      fontSize="small"
                                      className="text-red-500"
                                    />
                                    // <MinusCircleIcon className="mt-1.5 justify-items-end text-red-500 w-4 h-4" />
                                  )
                                ) : null}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List>
                                {item.Video.map(
                                  (
                                    file: any,
                                    i: React.Key | null | undefined
                                  ) => {
                                    return (
                                      <ListItem key={i} disablePadding>
                                        <ListItemButton
                                          onClick={(e) => handleplayer(file)}
                                        >
                                          <img
                                            alt=""
                                            className="w-20 h-12"
                                            src="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg"
                                            srcSet="https://img-c.udemycdn.com/course/240x135/1513876_9dfb_2.jpg 1x, https://img-c.udemycdn.com/course/480x270/1513876_9dfb_2.jpg 2x"
                                          />
                                          <Typography className="flex justify-between pl-3">
                                            {file.name}
                                            {completed ? (
                                              completed.video.includes(
                                                file._id
                                              ) ? (
                                                // <CheckCircleIcon className="mt-1.5 justify-items-end text-indigo-500 w-4 h-4" />
                                                <CheckCircleIcon
                                                  fontSize="small"
                                                  className="text-indigo-500"
                                                />
                                              ) : (
                                                // <MinusCircleIcon className="mt-1.5 justify-items-end text-red-500 w-4 h-4" />
                                                <RemoveCircleIcon
                                                  fontSize="small"
                                                  className="text-red-500"
                                                />
                                              )
                                            ) : null}
                                          </Typography>
                                        </ListItemButton>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    )
                  ) : (
                    <div>no course</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white drop-shadow-2xl rounded-2xl p-5 mb-10 m-5 my-5 grid grid-rows-1 ">
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleTabs}
              className="text-indigo-500"
              aria-label="secondary tabs example"
            >
              <Tab value={0} label="OverView" />
              <Tab value={1} label="Review" />
            </Tabs>
          </Box>
          <TabPanel index={0} value={value}>
            <span className="flex p-5 ">{state.Lesson.description}</span>
          </TabPanel>

          <TabPanel index={1} value={value}>
            <ReviewForm course={course} />
          </TabPanel>
        </div>
      </div>
      <FooterScreen />
    </>
  );
};
export default NowLearning;
